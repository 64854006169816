import React from "react";
import { Interrogator, QuestionsObj } from "ewoq/containers/Interrogator";
import questions from "./Questions/MCTQuestions";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";
import MCTQscore from "./MCTQscorer";
import { En, De } from "ewoq/components/Language";
import { answerQuestionnaire } from "ewoq/api";

const MessageText = styled.div`
  text-align: justify;
`;

interface Props {
  onDone: (s: boolean) => void;
  viewAll?: boolean;
}

class MCTQ extends React.Component<Props> {
  onSubmit = (answers: QuestionsObj) => {
    const score = MCTQscore(answers);
    let isEligible = Object.values(answers).every(a => a.isOk);
    if (score !== undefined) {
      console.log("mctq score is: ", score);
      isEligible = isEligible && score > 2 && score < 6.5;
    }

    answerQuestionnaire("LSM", "Screening-MCTQ", {
      answers: answers,
      scores: score,
      isEligible: isEligible
    });

    this.props.onDone(isEligible);
  };

  render() {
    return (
      <div>
        <Ui.Message warning>
          <Ui.MessageHeader>
            <span>
              <En>Be careful!</En>
              <De>Seien Sie vorsichtig!</De>
            </span>
          </Ui.MessageHeader>
          <Ui.MessageContent>
            <MessageText>
              <span>
                <En>
                  Some of the questions may seem identical to the previous ones,
                  but they are asking slightly different things, and your answer
                  could be both the same or different than before.
                </En>
                <De>
                  Einige Fragen scheinen ähnlich wie vorherige, aber sie fragen
                  nach leicht verschiedenen Dingen. Ihre Antworten können dabei
                  gleich, aber auch anders sein als zuvor.
                </De>
              </span>
            </MessageText>
          </Ui.MessageContent>
        </Ui.Message>
        <Interrogator
          questions={questions}
          onDone={this.onSubmit}
          viewAll={this.props.viewAll}
        />
      </div>
    );
  }
}

export default MCTQ;
