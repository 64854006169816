import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";

import {
  QuestionTitle,
  QuestionDescription,
  GenericAnswerContainer,
  QuestionArea
} from "ewoq/prototypes/Misc";

const QuestionBox = styled.div`
  // margin: 0.5em;
  margin-bottom: 10em;
`;

const SkipArea = styled.div`
  padding: 2em;
  margin-right: -2em;
`;

const LabelArea = styled.div`
  max-width: 30ch;
  text-align: justify;
`;

enum QuestionVisibility {
  NotShow,
  Show,
  WillShow
}

export interface AnswerObj {
  data?: object;
  questionProps?: object;
  type?: string;
}

export interface QuestionType {
  id: string;
  title: any;
  plainTitle?: string;
  explanation?: any;
  mandatory?: boolean; // questin is mandatory when true or undefined
  warning?: any;
  tip?: any;
  component: (onValid: (state?: AnswerObj) => void) => any;
  isOk?: (state: any) => boolean;
  shouldShow?: (state: Question[]) => boolean; //might need to add Void
} // these are the things that can come with each question

export interface Question {
  data?: any;
  questionProps?: any;
  id: string;
  type?: string;
  order: number;
  title: string;
  isOk: boolean;
  timestamp?: Date;
  attemptsCount: number;
  warning: any;
  // materialized fields
  mandatory: boolean;
  wasShown: boolean;
} // this is the stuff that characterizes each answer

export interface QuestionsObj {
  [s: string]: Question;
} // this is the JSON that gets spit out with all the answers

interface Props {
  questions: QuestionType[];
  onDone: (answers: QuestionsObj) => void;
  viewAll?: boolean;
}

export interface State {
  answers: Question[];
  isEmptyQuestionsModalOpen: boolean;
  areEmptyAnswersHighlighted: boolean;
}

export class Interrogator extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const answers: Question[] = this.props.questions.map((q, idx) => ({
      data: undefined,
      questionProps: undefined,
      type: undefined,
      id: q.id,
      title: q.plainTitle || q.title,
      order: idx,
      warning: q.warning,
      isOk: q.isOk === undefined,
      timestamp: undefined,
      attemptsCount: 0,
      mandatory: q.mandatory === undefined || q.mandatory,
      wasShown: false
    }));
    this.state = {
      answers,
      isEmptyQuestionsModalOpen: false,
      areEmptyAnswersHighlighted: false
    };
  }

  questionsToShow = (): QuestionVisibility[] => {
    // speculatively set all questions to .WillShow
    let toShow = Array(this.props.questions.length).fill(
      QuestionVisibility.WillShow
    );

    // set first question to .Show
    toShow[0] = QuestionVisibility.Show;

    // set all questions up to "the next one to be shown" to .Show
    let lastAnswerEverFilled = 0;
    this.state.answers.forEach((a, idx) => {
      if (a.data) {
        lastAnswerEverFilled = idx;
      }
    });

    toShow.forEach((v, idx) => {
      if (idx === 0) {
        return;
      }

      const prev = idx - 1;
      const q = this.props.questions[prev];
      const prevHasData = this.state.answers[prev].data;

      const prevShouldShow =
        q.shouldShow === undefined || q.shouldShow(this.state.answers);

      if (
        (toShow[prev] === QuestionVisibility.Show &&
          (!prevShouldShow || prevHasData)) ||
        idx <= lastAnswerEverFilled
      ) {
        toShow[idx] = QuestionVisibility.Show;
      }
    });

    // set all question that don't pass the shouldShow test to .NotShow
    toShow.forEach((v, idx) => {
      const q = this.props.questions[idx];
      const shouldShow =
        q.shouldShow === undefined || q.shouldShow(this.state.answers);
      if (!shouldShow) {
        toShow[idx] = QuestionVisibility.NotShow;
      }
    });

    return toShow;
  };

  scrollToBottom = () => {
    setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 100);
  };

  onValid = (idx: number) => (s?: AnswerObj) => {
    const answer = this.state.answers[idx];

    if (answer.attemptsCount === 0 && !this.props.viewAll) {
      this.scrollToBottom();
    }

    const question = this.props.questions[idx];

    answer.data = s ? s.data : undefined;
    answer.questionProps = s ? s.questionProps : undefined;
    answer.type = s ? s.type : undefined; //TODO see with Simone if can be

    if (s && s.data) {
      answer.isOk = question.isOk === undefined || question.isOk(s.data);
    }

    answer.attemptsCount++;
    answer.timestamp = new Date();
    answer.wasShown = true;
    const answers = this.state.answers.slice();
    answers[idx] = answer;

    this.setState({ answers });
  };

  onSkip = (idx: number) => () => {
    this.onValid(idx)({ data: { answer: "skipped" } });
  };

  questionColor = (idx: number) => {
    let color = undefined;
    if (
      this.state.areEmptyAnswersHighlighted &&
      !this.state.answers[idx].data
    ) {
      if (this.state.answers[idx].mandatory) {
        color = "red";
      } else {
        color = "orange";
      }
    }
    return color;
  };

  onSubmit = () => {
    if (
      this.state.answers.every(
        a => a["data"] !== undefined || !a["wasShown"]
      ) ||
      (this.props.viewAll && this.state.answers)
    ) {
      const obj: QuestionsObj = {};
      this.state.answers.forEach(answer => {
        obj[answer.id] = answer;
      });
      this.props.onDone(obj);
    } else {
      this.setState({ isEmptyQuestionsModalOpen: true });
    }
  };

  onModalClosed = () => {
    this.setState({
      isEmptyQuestionsModalOpen: false,
      areEmptyAnswersHighlighted: true
    });
  };

  renderModal = () => (
    <Ui.Modal open={this.state.isEmptyQuestionsModalOpen} basic>
      <Ui.Header
        icon="edit"
        content="Oops! Some mandatory questions were not filled in..."
      />
      <Ui.Modal.Content>
        <p>
          Please go over your answers again. Questions with missing answers are
          marked in red. Questions with missing answers that you had the option
          to skip are marked in yellow.
        </p>
      </Ui.Modal.Content>
      <Ui.Modal.Actions>
        <Ui.Button onClick={this.onModalClosed} inverted>
          Edit Answers
        </Ui.Button>
      </Ui.Modal.Actions>
    </Ui.Modal>
  );

  render() {
    const toShow = this.questionsToShow();

    let lastShownQuestion = 0;
    toShow.forEach((v, idx) => {
      if (v === QuestionVisibility.Show) {
        lastShownQuestion = idx;
      }
    });

    // this is used only as a boolean to determine wethere there are more Qs to show
    const nextQuestionToShowIdx = toShow.find(
      (v, idx) => idx > lastShownQuestion && v === QuestionVisibility.WillShow
    );

    // Calculates progress bar
    const unAnswered = toShow.filter(
      (q, idx) =>
        (q === QuestionVisibility.Show &&
          this.state.answers[idx].data === undefined) ||
        q === QuestionVisibility.WillShow
    ).length;
    const answered = toShow.filter(
      (q, idx) =>
        q === QuestionVisibility.Show &&
        this.state.answers[idx].data !== undefined
    ).length;

    let progress = Math.floor((answered / (unAnswered + answered)) * 100);
    return (
      <div>
        <QuestionBox>
          {this.props.questions.map((q, idx) =>
            !(
              toShow[idx] === QuestionVisibility.Show || this.props.viewAll
            ) ? null : (
              <Ui.Segment key={q.id} color={this.questionColor(idx) as any}>
                <QuestionArea>
                  <QuestionTitle>{q.title}</QuestionTitle>
                  <QuestionDescription>{q.explanation}</QuestionDescription>
                </QuestionArea>
                <GenericAnswerContainer>
                  {q.component(this.onValid(idx))}
                </GenericAnswerContainer>
                {q.tip && idx === lastShownQuestion && (
                  <LabelArea>
                    <Ui.Label color="green" pointing>
                      {q.tip}
                    </Ui.Label>
                  </LabelArea>
                )}
                {q.warning &&
                  this.state.answers[idx].data &&
                  this.state.answers[idx].isOk === false &&
                  idx < lastShownQuestion && (
                    <LabelArea>
                      <Ui.Label color="yellow" pointing>
                        {q.warning}
                      </Ui.Label>
                    </LabelArea>
                  )}
                {q.mandatory === false && idx === lastShownQuestion && (
                  <SkipArea>
                    <Ui.Button
                      floated="right"
                      size="small"
                      onClick={this.onSkip(idx)}
                    >
                      SKIP
                    </Ui.Button>
                  </SkipArea>
                )}
              </Ui.Segment>
            )
          )}
          <Ui.Progress progress percent={progress} color="violet" />

          {((nextQuestionToShowIdx === undefined && progress === 100) ||
            this.props.viewAll) && (
            <Ui.Button onClick={this.onSubmit} positive floated="right">
              Submit!
            </Ui.Button>
          )}
          {this.state.isEmptyQuestionsModalOpen && this.renderModal()}
          {this.props.viewAll
            ? console.log(JSON.stringify(this.state.answers))
            : null}
        </QuestionBox>
      </div>
    );
  }
}
