import React from "react";
import * as Ui from "semantic-ui-react";

import { HeaderContainer, ParticipantID } from "ewoq/components/miscStyles";
import ChooseSession from "ewoq/containers/DayPicker";
import { getUserId } from "ewoq/utils";
import { QuestionsObj, Interrogator } from "ewoq/containers/Interrogator";
import { answerQuestionnaire } from "ewoq/api";

import questions from "./RestTestQuestions";

interface Props {
  viewAll?: boolean;
}

interface State {
  day: string;
  block: string;
}

class Lifestyle extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      day: "",
      block: ""
    };
  }

  onSubmit = (answers: QuestionsObj) => {
    answerQuestionnaire(
      "LSM",
      "RRT-" + this.state.day + "-" + this.state.block,
      {
        answers: answers
      }
    );
    this.setState({ block: "" });
  };
  chooseBlock = (selection: string) => {
    this.setState({ block: selection });
  };

  chooseDay = (selection: string) => {
    this.setState({ day: selection });
  };

  render() {
    return (
      <div>
        <ParticipantID>{getUserId()}</ParticipantID>
        <HeaderContainer>Rest Test Questionnaire</HeaderContainer>
        <Ui.Container>
          <ChooseSession
            sessions={[["Adaptation", "Main"]]}
            selection={this.state.day}
            onClick={this.chooseDay}
            title="Day"
          />
          <ChooseSession
            sessions={[
              ["Evening", "Morning"],
              ["1", "2", "3"],
              ["4", "5", "6"],
              ["7", "8", "9"],
              ["MWT"]
            ]}
            selection={this.state.block}
            onClick={this.chooseBlock}
            title="Block"
          />
        </Ui.Container>
        {this.state.block && this.state.day ? (
          <Interrogator
            questions={questions}
            onDone={this.onSubmit}
            viewAll={this.props.viewAll}
          />
        ) : null}
      </div>
    );
  }
}

export default Lifestyle;
