import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Radio from "ewoq/prototypes/Radio";
import * as Input from "ewoq/prototypes/TypeInput";
import * as YesNoQ from "ewoq/prototypes/YesNo";
import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";
import * as Slider from "ewoq/prototypes/Slider";
import * as SliderGroup from "ewoq/prototypes/SliderGroup";
import { Answer } from "ewoq/prototypes/GroupQuestions";

const questions: QuestionType[] = [
  {
    id: "SR_DRE_1",
    mandatory: true,
    title: <span>Did you dream?</span>,
    plainTitle: "Did you dream?",
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: "Yes" },
          { text: "No" },
          { text: "Not sure" },
          { text: "Yes, but I don't remember anything" }
        ]}
      />
    )
  },
  {
    id: "SR_DRE_2",
    mandatory: false,
    plainTitle: "Dream description:",
    title: (
      <span>
        Please describe your dreams in as much detail as you can remember:
      </span>
    ),
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => (
      <Input.Question onValid={v} large={true} inputType="string" />
    )
  },
  {
    id: "SR_DRE_3",
    mandatory: false,
    title: <span>Was it a lucid dream?</span>,
    plainTitle: "Lucid dream?",
    explanation: "This is a dream in which you are aware you are dreaming.",
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_DRE_4",
    mandatory: false,
    plainTitle: "Related to real life?",
    title: (
      <span>
        Was the content related to any recent experiences in your life?
      </span>
    ),
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_DRE_5.1",
    mandatory: false,
    plainTitle: "Emotion level of dream:",
    title: <span>In general, how emotional was the dream?</span>,
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Completely neutral", "Extremely emotional"]}
      />
    )
  },
  {
    id: "SR_DRE_5.2",
    mandatory: false,
    plainTitle: "Emotions in dream:",
    title: <span>What was the emotional tone during the dream?</span>,
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_5.1");
      return a[idx].data !== undefined && a[idx].data["answer"] > 0.25;
    },
    component: v => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          { question: "Happy", labels: ["Not at all", "Dominant"] },
          { question: "Fearful" },
          { question: "Angry" },
          { question: "Disgusted" },
          { question: "Sad/Sorrowful", labels: ["Not at all", "Dominant"] },
          { question: "Tense/Stressed" }
        ]}
      />
    )
  },
  {
    id: "SR_DRE_5.3",
    mandatory: false,
    plainTitle: "Other emotions:",
    title: "Were other emotions involved?",
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_5.1");
      return a[idx].data !== undefined && a[idx].data["answer"] > 0.25;
    },
    component: v => (
      <Input.Question onValid={v} inputType="string" extraRadio={["None"]} />
    )
  },
  {
    id: "SR_DRE_6",
    mandatory: false,
    plainTitle: "Senses involved:",
    title: <span>Which senses were involved?</span>,
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "Visual" },
          { text: "Auditory" },
          { text: "Olfactory (smell)" },
          { text: "Taste" },
          { text: "Balance" },
          { text: "Other", extraInput: "string" }
        ]}
        allAbove
      />
    )
  },
  {
    id: "SR_DRE_7.1",
    mandatory: false,
    plainTitle: "Dream vividness:",
    title: <span>How vivid was your dream?</span>,
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Extremely hazy", "Extremely vivid"]}
      />
    )
  },
  {
    id: "SR_DRE_7.2",
    mandatory: false,
    plainTitle: "Dream realism:",
    title: <span>How realistic was your dream?</span>,
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Extremely crazy", "Extremely realistic"]}
      />
    )
  },
  {
    id: "SR_DRE_7.3",
    mandatory: false,
    plainTitle: "Dream complexity:",
    title: <span>How complex was your dream?</span>,
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Simple", "Extremely complex"]}
      />
    )
  },
  {
    id: "SR_DRE_7.4",
    mandatory: false,
    plainTitle: "Dream pleasantness:",
    title: <span>How pleasant overall was your dream?</span>,
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Nightmare", "Wonderful"]}
      />
    )
  },
  {
    id: "SR_DRE_8.1",
    mandatory: false,
    plainTitle: "Dreamer's movement:",
    title: <span>How much did you move your body in the dream?</span>,
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Completely still", "Moving all the time"]}
      />
    )
  },
  {
    id: "SR_DRE_8.2",
    mandatory: false,
    plainTitle: "Other movement in dream:",
    title: <span>How much did other things move?</span>,
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Completely still", "Movement all the time"]}
      />
    )
  },
  {
    id: "SR_DRE_8.3",
    mandatory: false,
    plainTitle: "Travelling in dream:",
    title: <span>How much did you travel from place to place?</span>,
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Stayed in one place", "Always travelling"]}
      />
    )
  }
];

export default questions;

//TODO: add plain titles and change numbering, and update questions
