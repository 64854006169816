import React from "react";

import styled from "styled-components";

import * as Ui from "semantic-ui-react";
import { Interrogator, QuestionsObj } from "ewoq/containers/Interrogator";
import questions from "./DebriefingQuestions";
import { answerQuestionnaire } from "ewoq/api";

import { getUserId } from "ewoq/utils";

import { HeaderContainer, ParticipantID } from "ewoq/components/miscStyles";

const TextContent = styled.p`
  font-style: bold;
  font-size: 3em;
  text-align: center;
`;

interface Props {
  viewAll?: boolean;
}

interface State {
  modalOpen: boolean;
}

export class Debriefing extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { modalOpen: false }; //TODO: make this state get saved to disk
  }

  onSubmit = (answers: QuestionsObj) => {
    this.setState({ modalOpen: true });
    answerQuestionnaire("LSM", "Debriefing", {
      answers: answers,
    });
  };

  render() {
    return (
      <div>
        <Ui.Modal closeOnDimmerClick={false} open={this.state.modalOpen}>
          <Ui.Modal.Content>
            <TextContent>You're done!</TextContent>
          </Ui.Modal.Content>
        </Ui.Modal>
        <ParticipantID>{getUserId()}</ParticipantID>
        <HeaderContainer>Debriefing Questionnaire</HeaderContainer>
        <Ui.Container>
          <Interrogator
            questions={questions}
            onDone={this.onSubmit}
            viewAll={this.props.viewAll}
          />
        </Ui.Container>
      </div>
    );
  }
}

export default Debriefing;
