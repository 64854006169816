import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Input from "ewoq/prototypes/TypeInput";
import * as Slider from "ewoq/prototypes/Slider";
import { fourEnergiesPast } from "Questionnaires/StandardQuestionComponents/FourEnergies";
import { kss } from "Questionnaires/StandardQuestionComponents/KSS";
import { threeHoursDay } from "Questionnaires/StandardQuestionComponents/TimeofDay";

const questions: QuestionType[] = [
  {
    id: "SR_TIR_1.1",
    mandatory: true,
    plainTitle: "Time spent working yesterday:",
    title: (
      <span>
        How much of the previous day did you spend working / studying?
      </span>
    ),
    explanation: "Consider anything you don't consider fun or restful.",
    tip: "If you want, click on the labels to select exactly that answer",
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Didn't work", "Worked all the time"]}
      />
    )
  },
  {
    id: "SR_TIR_1.2",
    mandatory: true,
    title: <span>Were you overall more or less tired than usual?</span>,
    plainTitle: "More or less tired than usual?",
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Less", "Usual", "More"]}
      />
    )
  },
  {
    id: "SR_TIR_2.1",
    mandatory: false,
    plainTitle: "morning tired",
    title: (
      <span>
        Please indicate how tired you felt on average in the <b>morning</b>:
      </span>
    ),
    component: v => kss(v)
  },
  {
    id: "SR_TIR_2.2",
    mandatory: false,
    plainTitle: "evening tired",
    title: (
      <span>
        Please indicate how tired you felt on average in the <b>evening</b>:
      </span>
    ),
    component: v => kss(v)
  },
  {
    id: "SR_TIR_2.3",
    mandatory: false,
    plainTitle: "Did you want to nap?",
    title: <span>Would you have liked to take a nap?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Not at all", "Absolutely"]}
      />
    )
  },
  {
    id: "SR_TIR_4.1",
    mandatory: true,
    plainTitle: "preferred sleep time",
    title: <span>When would you have preferred to go to sleep?</span>,
    component: v => (
      <Input.Question
        onValid={v}
        inputType="time"
        extraRadio={["When I did"]}
      />
    )
  },
  {
    id: "SR_TIR_4.2",
    mandatory: true,
    plainTitle: "preferred wake time",
    title: <span>When would you have preferred to wake up?</span>,
    component: v => (
      <Input.Question
        onValid={v}
        inputType="time"
        extraRadio={["When I did"]}
      />
    )
  },
  {
    id: "SR_TIR_5",
    mandatory: false,
    plainTitle: "Energy end of day:",
    title: (
      <span>
        In the second half of the day, indicate on the slider how you felt.
      </span>
    ),
    component: v => fourEnergiesPast(v)
  }
];
export default questions;
