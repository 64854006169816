import React from "react";
import * as Ui from "semantic-ui-react";

import {
  SectionBody,
  HeaderContainer,
  ParticipantID
} from "ewoq/components/miscStyles";
import SleepAssessment from "./SleepAssessment";
import CurrentState from "./CurrentState";
import NeedRescheduling from "./NeedRescheduling";
import Tiredness from "./Tiredness";
import Activities from "./Activities";
import FoodDrinks from "./FoodDrinks";
import Feelings from "./Feelings";
import Dreams from "./Dreams";

import { getUserId } from "ewoq/utils";

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];
const today = new Date();

enum Phase {
  Start,
  Dreams,
  SleepAssessment,
  Activities,
  CurrentState,
  Feelings,
  FoodDrinks,
  Tiredness,
  NeedRescheduling
}

interface Props {}

interface State {
  phase: Phase;
  sleepReport: string;
  dayReport: string;
}

class SleepReport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let dayReportIndex = today.getDay();
    console.log(dayReportIndex);
    if (today.getHours() < 12) {
      dayReportIndex = (dayReportIndex - 1 + 7) % 7;
    } //TODO: make sure this is working ok
    console.log(dayReportIndex);
    this.state = {
      phase: Phase.Start,
      sleepReport: days[dayReportIndex],
      dayReport: days[dayReportIndex]
    };
  }

  advancePhase = (newPhase: Phase) => () => {
    this.setState({ phase: newPhase });
  };

  onChange = (a: any, b: any) => {
    this.setState({ sleepReport: b.value });
  };

  render() {
    switch (this.state.phase) {
      case Phase.Start: {
        return (
          <div>
            <ParticipantID>{getUserId()}</ParticipantID>
            <HeaderContainer>Sleep Report</HeaderContainer>
            <Ui.Container>
              <Ui.Segment>
                <Ui.Divider horizontal>Instructions</Ui.Divider>
                <SectionBody>
                  Please fill in these questionnaires every morning after you’ve
                  woken up. Please fill in the dream report first thing, before
                  doing anything else, because memories of dreams fade really
                  quickly. The sleep report you can fill in any time in the
                  morning, but please always do so at the same time every day.
                  The report on the previous day can either be done the evening
                  before, or the next morning with the sleep report.
                  <p>
                    {" "}
                    Questions that ask more sensitive ore less critical
                    information are skipable, although we appreciate all the
                    answers you can give us.{" "}
                  </p>
                </SectionBody>
                <Ui.Segment.Group raised>
                  <Ui.Segment textAlign="center">
                    <Ui.Divider horizontal>{this.state.sleepReport}</Ui.Divider>
                    <p>
                      The report you are about to fill out will be labeled "
                      {this.state.sleepReport}". It refers to the day "
                      {this.state.sleepReport}", and last night. If you wish to
                      fill out a sleep or day report related to a different day,
                      you may write a custom name, but please choose something
                      meaningful.
                    </p>
                    <Ui.Form>
                      <Ui.Form.Field></Ui.Form.Field>
                      <Ui.Form.Field width={5}>
                        <label>Custom name</label>
                        <Ui.Input
                          placeholder="Custom name"
                          value={this.state.sleepReport}
                          onChange={this.onChange}
                        />
                      </Ui.Form.Field>
                    </Ui.Form>
                  </Ui.Segment>
                  <Ui.Segment>
                    <Ui.Form>
                      <Ui.Form.Field>
                        <SectionBody>
                          <Ui.Button
                            fluid
                            onClick={this.advancePhase(Phase.Dreams)}
                            disabled={this.state.sleepReport === undefined}
                          >
                            Dreams
                          </Ui.Button>
                        </SectionBody>
                        <SectionBody>
                          <Ui.Button
                            fluid
                            onClick={this.advancePhase(Phase.SleepAssessment)}
                            disabled={this.state.sleepReport === undefined}
                          >
                            Last night sleep report
                          </Ui.Button>
                        </SectionBody>
                        <SectionBody>
                          <Ui.Button
                            fluid
                            onClick={this.advancePhase(Phase.Tiredness)}
                            disabled={this.state.sleepReport === undefined}
                          >
                            {this.state.dayReport + " activity report"}
                          </Ui.Button>
                        </SectionBody>
                      </Ui.Form.Field>
                    </Ui.Form>
                  </Ui.Segment>
                </Ui.Segment.Group>
              </Ui.Segment>
            </Ui.Container>
          </div>
        );
      }
      case Phase.Dreams: {
        return (
          <Dreams
            name={this.state.sleepReport}
            onDone={this.advancePhase(Phase.Start)}
          />
        );
      }
      case Phase.SleepAssessment: {
        return (
          <SleepAssessment
            name={this.state.sleepReport}
            onDone={this.advancePhase(Phase.CurrentState)}
          />
        );
      }
      case Phase.CurrentState: {
        return (
          <CurrentState
            name={this.state.sleepReport}
            onDone={this.advancePhase(Phase.NeedRescheduling)}
          />
        );
      }
      case Phase.NeedRescheduling: {
        return (
          <NeedRescheduling
            name={this.state.sleepReport}
            onDone={this.advancePhase(Phase.Start)}
          />
        );
      }
      case Phase.Tiredness: {
        return (
          <Tiredness
            name={this.state.sleepReport}
            onDone={this.advancePhase(Phase.Activities)}
          />
        );
      }
      case Phase.Activities: {
        return (
          <Activities
            name={this.state.sleepReport}
            onDone={this.advancePhase(Phase.FoodDrinks)}
          />
        );
      }
      case Phase.FoodDrinks: {
        return (
          <FoodDrinks
            name={this.state.sleepReport}
            onDone={this.advancePhase(Phase.Feelings)}
          />
        );
      }
      case Phase.Feelings: {
        return (
          <Feelings
            name={this.state.sleepReport}
            onDone={this.advancePhase(Phase.Start)}
          />
        );
      }
    }
  }
}

export default SleepReport;
