// here the layout of the homepage is established, and calls the sections to be filled in

import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";
import sections from "./sections";

import { SectionBody, HeaderContainer } from "ewoq/components/miscStyles";

const SectionBodySegment = styled.div`
  @media only screen and (min-width: 600px) {
    margin-left: 1em;
    margin-right: 1em;
  }
  margin-bottom: 1em;
`;

const Home: React.SFC = () => (
  <div>
    {/* <LinksArea>
      <a href="/scienceinfo">
        <Ui.Button
          content={"The science!"}
          icon="arrow right"
          compact
          size="tiny"
          floated="right"
          labelPosition="right"
        />
      </a>
    </LinksArea> */}

    <HeaderContainer>
      Participate in a weekend sleep experiment!
    </HeaderContainer>
    <Ui.Container>
      {sections.map((section, idx) => (
        <Ui.Segment key={idx}>
          <span id={`${idx}-home-section`}></span>{" "}
          <SectionBodySegment>
            <Ui.Divider horizontal>{section.title}</Ui.Divider>
            <SectionBody>{section.body}</SectionBody>
          </SectionBodySegment>
        </Ui.Segment>
      ))}
    </Ui.Container>
  </div>
);

export default Home;
