// these are the sections of the home page, as well as all the popups that get called.

import React from "react";
import InfoModal from "ewoq/components/InfoModal";
import styled from "styled-components";
import * as Ui from "semantic-ui-react";
import WhyPopup from "ewoq/components/WhyPopup";
import Figure from "ewoq/components/Figure";
import Schedule from "./icons/Schedule.svg";
import Person from "ewoq/components/Person";
import Huber from "./icons/Huber.jpeg";
import Snipes from "./icons/Snipes.jpg";
import Meier from "./icons/Meier.jpg";
import { Squeeze } from "ewoq/components/miscStyles";

const Highlights = styled.span`
  color: black;
  font-weight: bold;
`;

const Obligations = (
  <div>
    <Ui.Divider horizontal>Obligations</Ui.Divider>
    <Ui.SegmentGroup>
      <Ui.Segment>Follow the instructions</Ui.Segment>
      <Ui.Segment>Answer the questionnaires honestly</Ui.Segment>
      <Ui.Segment>
        inform the experimenter of any changes to your health or schedule that
        could interfere with the experiment; it is not necessary to specify
        what.
      </Ui.Segment>
      <Ui.Segment>
        Reschedule the experiment if anything interferes with your sleep or
        health before the experiment
      </Ui.Segment>
      <Ui.Segment>During the control week: </Ui.Segment>
      <Ui.SegmentGroup>
        <Ui.Segment>
          Don’t consume caffeine in the afternoon after 4PM (coffee, caffeinated
          soft drinks, energy drinks, black or green tea, etc.).
        </Ui.Segment>
        <Ui.Segment>
          Do not drink more than 2 portions of caffeinated beverages per day
          (e.g. only 2 coffees, or 1 double espresso, or one coffee and one Red
          Bull).
        </Ui.Segment>
        <Ui.Segment>
          Do not drink any alcohol, or take any drugs, or smoke anything at all.
        </Ui.Segment>
        <Ui.Segment>
          Don’t have any early morning activities that require you to set an
          earlier alarm than planned.
        </Ui.Segment>
        <Ui.Segment>
          Avoid parties or other events that might delay your sleep schedule.
        </Ui.Segment>
        <Ui.Segment>
          Wear the actimetry watch the whole time, but especially while
          sleeping. If the data shows periods of more than 2h when it wasn’t
          worn, and you didn’t inform the experimenter, you may be disqualified
          from continuing the experiment.
        </Ui.Segment>
        <Ui.Segment>
          Every morning before breakfast, fill out the Sleep Report. It is
          important that you do so before doing anything else because we ask
          about dreams, whose memory fades very quickly. It is acceptable to be
          up to 2 hours late.
        </Ui.Segment>
        <Ui.Segment>
          Contact the experimenter if you have any doubts if something is ok to
          do, and carefully report things that happened that you suspect could
          be relevant.
        </Ui.Segment>
      </Ui.SegmentGroup>
      <Ui.Segment>During the main experiment, please:</Ui.Segment>
      <Ui.SegmentGroup>
        <Ui.Segment>
          Arrive on time to the appointment. You will have a lot to do, and
          serious delays would require rescheduling the whole experiment.
        </Ui.Segment>
        <Ui.Segment>
          Sleep when asked and stay awake when asked. We understand that this is
          intentionally difficult, and it does require some effort on your part.
        </Ui.Segment>
        <Ui.Segment>
          Go to the toilet and take care of other needs during the Free Time
          periods, not elsewhere in the experiment. These are meant as
          unrecorded times for your benefit; deviating from the scheduled
          activities could invalidate the whole experiment.
        </Ui.Segment>
        <Ui.Segment>
          Follow the instructions, engage in the tasks, and ask questions if
          anything is unclear.
        </Ui.Segment>
      </Ui.SegmentGroup>
    </Ui.SegmentGroup>
  </div>
);

const Reschedule = (
  <div>
    <p>
      To ensure that your sleep is as normal as possible for the main
      experiment, you will need to reschedule if any of the following happen:
    </p>
    <ul>
      <li>Take any drugs</li>
      <li>Get seriously sick (e.g. flu) or injured (e.g. broken arm)</li>
      <li>Travel across 2 or more time zones</li>
      <li>5 or more consecutive nights of poor sleep, for whatever reason</li>
      <li>Sleep deprivation (staying awake longer than 20 hours)</li>
      <li>
        Sleep restriction (sleeping less than 6 hours a night for at least 3
        consecutive nights)
      </li>
    </ul>

    <p>Less severe but still requiring rescheduling includes:</p>
    <ul>
      <li>A cold or similar small illness (can still go to work)</li>
      <li>Getting a vaccine</li>
      <li>Drinking alcohol so much you can't walk / passed out</li>
      <li>Menstruation</li>
      <li>Exams</li>
      <li>Smoke/vape/nicotine patch</li>
      <li>Festival or other exhausting event</li>
      <li>Long trip</li>
      <li>Terrible night of sleep</li>
    </ul>
  </div>
);

const Payment = (
  <div>
    <p>
      Completing different stages of the experiment will result in incremental
      payments, according to the following criteria:
    </p>
    <ul>
      <li>10 CHF for completing the Lifestyle Questionnaire</li>
      <li>50 CHF in total if you also complete the Adaptation Night</li>
      <li>100 CHF for the first night of the main experiment</li>
      <li>200 CHF for partially completing the 24h wake period</li>
      <li>400 CHF in total for making it all the way to the end</li>
    </ul>
    <p>Transportation costs will also be covered.</p>
    <p>Reasons for not completing the experiment would include:</p>
    <ul>
      <li>
        You realize you don't wish to participate anymore (no need to give a
        reason) and choose to withdraw
      </li>
      <li>An error happens on our end, and the experiment gets interrrupted</li>
      <li>
        A medical condition or other disqualifying element is revealed, and you
        no longer meet the screening criteria
      </li>
      <li>
        You don't follow the{" "}
        <InfoModal trigger="Obligations">{Obligations}</InfoModal>
      </li>
    </ul>
  </div>
);

const Anonymity = (
  <div>
    <p>
      All answers provided in the screening questionnaire will be collected and
      stored completely anonymously; at no point do we ask for your name,
      address or other personal identifiable information.
    </p>
    <p>
      Instead, you will receive at the end of the questionnaire a 3 word code.
      Write it down, or memorize it. Only once you come to the main experiment
      and tell us your name and code will we know which questionnaire answers
      belong to you. The association between the code and your name will be
      saved in an encrypted file on secure Kinderspital computers.
    </p>
  </div>
);

const Confidentiality = (
  <div>
    <p>
      Personal data (e.g. name, address, date of birth) will be coded. “Coding”
      means that all data that could identify you are replaced by a randomly
      generated code. The decoding code will always remain encrypted within the
      institution.
    </p>
    <p>
      Only a few people will have access to your uncoded data and only to
      perform tasks necessary for the project. All persons who are in possession
      of the data in the study are bound by confidentiality. People who do not
      know the code will not be able to draw any conclusions about your
      identity.
    </p>
    <p>
      For publication, we will render publicly available your individual coded
      EEG data, actigraphy data, and non-sensitive questionnaire data (e.g.
      handedness, gender). The data cannot therefore be traced back to you. Your
      name will never appear on the Internet or in a publication. Data
      protection regulations are respected and you as a participant can have
      access to your data at any time.
    </p>
  </div>
);

const sections = [
  {
    title: "Intro",
    body: (
      <div>
        <p>
          This is a non-clinical study to understand the fundamental mechanisms
          of sleep and wake under high sleep pressure. We use{" "}
          <a
            href="https://simple.wikipedia.org/wiki/Electroencephalography"
            target="_blank"
            rel="noopener noreferrer"
          >
            EEG
          </a>{" "}
          to measure the different brain activity across different times of day
          and tasks. In particular, we aim to determine whether parts of the
          brain can selectively fall asleep. To do this, we employ sleep
          restriction to make you as tired as possible. This involves{" "}
          <strong>
            coming to the lab for 2 nights, staying awake for 24 hours in
            between, watching a TV series of your choice and conducting
            cognitive tests.
          </strong>
        </p>
        <Squeeze>
          <Ui.Segment raised color="violet">
            For participating, you will be paid <strong>400CHF</strong>. In the
            exceptional case that the experiment cannot be completed, you will
            be paid partial amounts (see{" "}
            <InfoModal trigger="Payment Details">{Payment}</InfoModal>).
          </Ui.Segment>
        </Squeeze>
      </div>
    )
  },
  {
    title: "Who can participate?",
    body: (
      <div>
        <p>
          Participants are screened so that they are very similar among each
          other, without introducing confounding elements that might bias the
          results. Furthermore, they must be able to handle the conditions of
          the experiment, without it being too different from their regular
          sleep-wake rhythms.
        </p>
        <Squeeze>
          <Ui.Segment raised color="green">
            <p>You must be:</p>
            <ul>
              <li>
                Between the ages of 18 and 25{" "}
                <WhyPopup>
                  Vulnerability to sleep pressure is highest in young people, so
                  the effect of prolongued wake is strongest, but we do not wish
                  to conduct this study in children.
                </WhyPopup>
              </li>
              <li>Healthy</li>
              <li>
                An average chronotype{" "}
                <WhyPopup>
                  This means you don't prefer to wake up extremely early, nor
                  extremely late
                </WhyPopup>
              </li>
              <li>A good sleeper</li>
            </ul>
          </Ui.Segment>
        </Squeeze>
        <Squeeze>
          <Ui.Segment raised color="red">
            <p>
              You will be <strong>excluded</strong> if you have or have done any
              of the following:
            </p>
            <ul>
              <li>
                Been diagnosed with or strongly suspected of having a
                psychiatric or medical condition
              </li>
              <li>Any learning disorders</li>
              <li>
                Sensitive skin{" "}
                <WhyPopup>
                  Some people with sensitive skin react negatively to the EEG
                  gel
                </WhyPopup>
              </li>
              <li>
                Currently in a period of stress, or generally being vulnerable
                to stress{" "}
                <WhyPopup>
                  This is usually related to lower than average sleep quality,
                  and you may be too stressed to even notice! It can also make
                  the experiment particularly intollerable.
                </WhyPopup>
              </li>
              <li>
                Currently required to wake up more than 2h before preferred
                wakeup time, or go to sleep 2h later than preferred{" "}
                <WhyPopup>
                  This is for practical reasons, you can't be forced to schedule
                  your sleep-wake pattern for the week before too early or too
                  late compared to your natural chronotype.
                </WhyPopup>
              </li>
              <li>
                Any sleep disorders, daytime sleepiness, or any other sleep
                complaints
              </li>
              <li>Nap regularly</li>
              <li>Pregnant</li>
              <li>
                Heavy consumer of:
                <ul>
                  <li>Caffeine</li>
                  <li>Alcohol</li>
                </ul>
              </li>
              <li>
                Regular consumer of:
                <ul>
                  <li>Drugs (any drug)</li>
                  <li>Nicotine</li>
                </ul>
              </li>
              <li>
                Currently taking medication{" "}
                <WhyPopup>
                  The only exception is contraception and medication for
                  external use.
                </WhyPopup>
              </li>
              <li>
                Any past experience with:
                <ul>
                  <li>Frequent drug use</li>
                  <li>Severe addiction and/or withdrawal</li>
                  <li>Alcoholism</li>
                </ul>
              </li>
              <li>
                Substantial experience with:{" "}
                <WhyPopup>
                  If you are "too experienced" with sleep restriction, you might
                  have very different reactions to this experiment then the rest
                  of the cohort.
                </WhyPopup>
                <ul>
                  <li>Shift work</li>
                  <li>Time zone travel</li>
                  <li>Sleep deprivation</li>
                </ul>
              </li>
            </ul>
          </Ui.Segment>
        </Squeeze>
        <p>
          If you're not sure, or hope that something doesn't apply exactly to
          your situation, it will become clear with the Screening Questionnaire
          (provided <a href={`#3-home-section`}>below</a>) if you are eligibile
          or not.
        </p>
        <h4>Risks</h4>
        <p>
          There are no risky procedures involved in this study if you are a
          healthy young participant without skin problems. Some people react
          negatively to the conductive gel used for the EEG, but these are
          individuals who tend to have multiple skin allergies, eczemas, etc.
          The effects of prolonged wakefulness are well documented in healthy
          people, but have different, often unknown, effects in patient
          populations.
        </p>
      </div>
    )
  },
  {
    title: "The experiment",
    body: (
      <div>
        <Ui.SegmentGroup>
          <Ui.Segment>
            <h5>Preparation for the Main Experiment:</h5>
          </Ui.Segment>
          <Ui.SegmentGroup>
            <Ui.Segment>
              <Highlights>Online screening procedure</Highlights>: this is to
              determine if you are eligibile to participate (see criteria{" "}
              <a href={`#1-home-section`}>below</a>
              ). It will ask you questions about your health, drinking, smoking
              and drug use, sleep habits and other aspects that would make you
              ineligibile to participate.
              <WhyPopup>
                This is important because we want to carefully control for
                external factors that affect sleep pressure and EEG, so that the
                experiment is especially senitive to only the changes over time
                and not individual differences. Larger scale studies are
                required to measure interpersonal differences.
              </WhyPopup>
            </Ui.Segment>
            <Ui.Segment>
              <Highlights>In-person meetings and questionnaires</Highlights>:
              Here you can ask questions in person, and sign the formal consent
              form. After this, you will be given the{" "}
              <Highlights>Lifestyle Questionnaire</Highlights>, asking you for
              more details about yourself.
              <WhyPopup>
                <p>
                  These are questions that ask about factors that could affect
                  different parameters of the experiment, but either we thought
                  their influence too small to matter, or there wasn't enough
                  evidence either way.
                </p>
                <p>
                  The questions cover socioeconomic status, eating and drinking
                  habbits, caffeine consumption, locations lived, sleep and wake
                  rhythms, hobbies, TV/gaming/excersize/etc, and more. Given how
                  detailed and long many questions are optional.
                </p>
              </WhyPopup>
              Lastly, you will be asked to establish a bedtime and wake up time
              that you find natural and can comply with for 1 week leading up to
              the main experiment.
            </Ui.Segment>
            <Ui.Segment>
              <Highlights>Adaptation night</Highlights>: Either the same day you
              sign consent, or shortly after, you will need to come to the lab
              for a night measurement. You will be equipped with the EEG
              electrodes, and asked to sleep within the agreed upon time window.
              <WhyPopup>
                This is to let you get used to the new sleeping environment well
                before the main experiment, and to confirm that you do not have
                any sleep problems.
              </WhyPopup>
            </Ui.Segment>
            <Ui.Segment>
              <p>
                <Highlights>Controlled sleep week</Highlights>: for 7 days
                before the experiment, you are asked go to sleep and wake up at
                the same pre-specified times, and avoid any behaviour that could
                affect your sleep (for the exact list of do's and don'ts, see{" "}
                <InfoModal trigger="Obligations)">{Obligations}</InfoModal>.
              </p>
              <p>
                Every morning right after waking up, you will be provided a{" "}
                <Highlights>Sleep Report</Highlights> to fill out, which asks
                you about the quality of your sleep, dreams, mood, and your
                activities from the day before. During this week, you will be
                given an{" "}
                <a
                  href="https://en.wikipedia.org/wiki/Actigraphy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  actigraphy
                </a>{" "}
                watch to measure activity levels. If{" "}
                <InfoModal trigger="anything happens">{Reschedule}</InfoModal>{" "}
                during this week that could affect your sleep, we will need to
                reschedule.
              </p>
            </Ui.Segment>
          </Ui.SegmentGroup>
          <Ui.Segment>
            {" "}
            <h5>Main Experiment:</h5>
            <Figure
              href={Schedule}
              color="violet"
              label="Main Experiment Schedule"
            >
              Here you can see how the day will be organized. The list below
              explains what each section refers to.
            </Figure>
          </Ui.Segment>
          <Ui.SegmentGroup>
            <Ui.Segment>
              <Highlights>Regular Rest Test</Highlights>: this is a wake EEG
              recording which involves staring at a fixation dot while letting
              your mind wander. You will have your head in a chin rest, and an
              eye-tracker will measure any eyelid closure and pupil size. At the
              end, you need to answer questions about your experience. You will
              do this before going to sleep, then every 2 hours the next day.{" "}
              <WhyPopup>
                The Regular Rest Test, repeated regularly, is used to
                systematically keep track of changes in resting state EEG over
                time.
              </WhyPopup>
              <p>
                During all EEG recordings, there will be a microphone recording
                audio, to keep track of everything that happens.
              </p>
            </Ui.Segment>
            <Ui.Segment>
              <Highlights>Baseline night</Highlights>: you will go to sleep at
              the regular hour, wearing the EEG net. At the midpoint of your
              sleep, around 4 hours later, we will wake you up.
            </Ui.Segment>
            <Ui.Segment>
              <Highlights>24 hours awake</Highlights>: this involves alternating
              between watching TV episodes,
              <WhyPopup>
                This is to give you something enjoyable to do most of the time,
                while also letting us record EEG without too much movement. You
                can choose the TV show, but it needs to have at least 13
                episodes per season, and each episode should last 40-45 minutes.
              </WhyPopup>
              Regular Rest Tests, cognitive tests,
              <WhyPopup>
                These will involve reaction time tasks (PVT), working memory
                tasks (Sternberg task), oddball tasks, music listening, and
                tetris (or similar). They are meant to measure different
                behavioral effects of sleepiness, as well as changes in EEG when
                compensating for sleepiness.
              </WhyPopup>
              and taking breaks.
              <WhyPopup>
                This will include meal times, let you stretch, walk around, go
                to the bathroom, check your phone, whatever. You won't be
                recorded in any way.
              </WhyPopup>
              During these breaks, you will be given a snack.
              <WhyPopup>
                You will get a snack for every break instead of 3 standard meals
                to keep constant the intake of calories. You can choose how to
                compose the snack.
              </WhyPopup>
            </Ui.Segment>
            <Ui.Segment>
              <Highlights>Recovery night</Highlights>: you will go to sleep at
              the same time you woke up (e.g. 3AM), and sleep in until you
              naturally wake up, as always with the EEG net.
            </Ui.Segment>
            <Ui.Segment>
              In the morning, you will do one last Regular Rest EEG, then will
              have the EEG taken off, and will be free to shower and get ready
              to leave. We will have you fill out the final{" "}
              <Highlights>Debriefing Questionnaire</Highlights>, asking you
              about your experience, then you’ll get paid and can go home.
            </Ui.Segment>
          </Ui.SegmentGroup>
        </Ui.SegmentGroup>
        <div>
          <p>
            Participation is entirely voluntary. If you decide to withdraw, you
            do not need to give a reason.
          </p>
          <p>
            This study was approved by the Kantonale Ethikkommission Zürich
            (BASEC ID: 2019-01193)
          </p>
          <p>
            For more information, you can read the{" "}
            <a
              href="/documents/3_Information_and_Consent_en.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Information and Consent
            </a>{" "}
            document, which is what you will need to sign before participating.
            You can also refer to the{" "}
            <a
              href="/documents/3_Information_and_Consent_de.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              German version
            </a>
            . In general, we recommend at least a conversational understanding
            of English, because experiment instructions and questionnaires will
            be in English. You will always be able to ask the experimenters for
            clarifications.
          </p>
        </div>
        <Ui.Divider hidden />
        <div>
          <h4>Burdens</h4>
          <p>
            There are some aspects of the experiment that you might find too
            burdensome. Please make sure you’re willing to withstand the
            following:
          </p>
          <ul>
            <li>
              Staying awake for 24 hours after only 4 hours of sleep. You will
              be monitored by the experimenters to make sure you don’t doze off.
              You will be <strong>tired</strong>.
            </li>
            <li>
              Wearing an EEG net for 36 hours continuously, also while sleeping.
              This is <strong>uncomfortable</strong>.
            </li>
            <li>
              Sticking to a strict schedule on when to fall asleep and wake up
              for a week. This is a burden if you have to reschedule events.
            </li>
            <li>
              Abstaining from coffee or other caffeinated beverages while in the
              lab, and limiting coffee in the week before.
            </li>
            <li>
              Abstaining from drugs,
              <WhyPopup>
                drugs obviously affect the brain, and these effects (even
                without you noticing) sometimes take up to 6 weeks before they
                are completely reversed. It is super important that you be
                completely recovered from any drug use by the time of the main
                experiment.
              </WhyPopup>
              alcohol,
              <WhyPopup>
                Like with coffee, the effects of alcohol are dose dependent. If
                you slip and have a beer 2-3 days before the experiment, it's
                still ok, but a big problem if it's the night before, or if you
                drink any amount that starts to have behavioral, cognitive and
                sleep effects.
              </WhyPopup>
              and partying
              <WhyPopup>
                Even without drinking or drugs, parties have the tendency to run
                late, and therefore screw up your sleep pattern.
              </WhyPopup>
              the entire week of the experiment. We’re sorry if participating in
              this experiment has a negative impact on your social life.
            </li>
            <li>Conducting boring tasks for extended periods of time.</li>
            <li>
              Eating the same set of snacks at 3h intervals instead of 3 normal
              meals during the 24h awake.
            </li>
          </ul>
        </div>
      </div>
    )
  },
  {
    title: "When and where",
    body: (
      <div>
        <p>
          The 2 nights in the lab and 24h awake is expected to be conducted on
          the weekend, but if scheduling allows, it may be possible to run the
          experiment during the week.{" "}
          <strong>
            All recording sessions will be conducted between January and March
            2020.
          </strong>
        </p>
        <p> The experiment will take place at the Irchel campus in Zurich.</p>
        <Squeeze>
          <iframe
            width="100%"
            height="300"
            src="https://maps.google.com/maps?q=wintherturestrasse%20190%2C%20zurich&t=&z=13&ie=UTF8&iwloc=&output=embed"
            scrolling="no"
          />
        </Squeeze>
      </div>
    )
  },
  {
    title: "How to participate",
    body: (
      <div>
        <p>
          To make sure you fit the criteria for participating, you can start by
          filling in this{" "}
          <InfoModal trigger="fully anonymous">{Anonymity}</InfoModal> screening
          questionnaire. Answers provided, as well as all data collected for the
          experiment, will be treated with{" "}
          <InfoModal trigger="full confidentiality.">
            {Confidentiality}
          </InfoModal>
        </p>
        <a href="/screening">
          <Ui.Button color="violet" fluid size="massive">
            Screening Questionnaire
          </Ui.Button>
        </a>
      </div>
    )
  },
  {
    title: "Who are we",
    body: (
      <div>
        <p>
          We are part of the{" "}
          <a
            href="https://www.kispi.uzh.ch/fzk/de/abteilungen/uebersicht/schlafzentrum/Seiten/default.aspx#a=akk1"
            target="_blank"
            rel="noopener noreferrer"
          >
            Child Development Center
          </a>{" "}
          at the University Children's Hospital of Zurich. We specialize in
          using high density EEG{" "}
          <WhyPopup>
            This just means we use 128 electrodes instead of the clinical
            standard of 20, or the research standard of 64. With this many
            electrodes, we can better see what happens in more restricted
            regions of the brain. It is also useful for sleep studies, because
            electrodes like to fall off when people roll around in bed at night.
          </WhyPopup>{" "}
          to study local changes in sleep and sleep pressure.
        </p>
        <p>
          <Person trigger="Prof. Reto Huber" picture={Huber}>
            He is the boss, and if you have any serious issues, he will be
            available to help.
          </Person>{" "}
          is the principal investigator, and{" "}
          <Person trigger="Sophia Snipes" picture={Snipes}>
            This whole thing is Sophia's research project. She's the one who
            running all of this, and is very happy to answer any questions.{" "}
          </Person>{" "}
          is the student conducting this study for her PhD thesis, with the help
          of{" "}
          <Person trigger="Elias Meier" picture={Meier}>
            Elias is doing his master thesis with this project, and is happy to
            answer any questions.
          </Person>
          .
        </p>
        <div>
          <Ui.Segment color="violet" compact>
            <p>If you have any qustions, you may contact Sophia directly:</p>
            <p>sophia.snipes@kispi.uzh.ch</p>
          </Ui.Segment>
        </div>
      </div>
    )
  },
  {
    title: "FAQ",
    body: (
      <div>
        <div>
          <p>
            <Highlights>What will I eat?</Highlights>
          </p>
          <p>
            We will provide dinner when you come to the first night of the
            experiment, and breakfast the morning of the last day. During the
            experiment you will get 6 small meals spaced out during the free
            periods. We will decide together what will make up this meal, but it
            has to be the same set of foods each time.{" "}
            <WhyPopup>
              This is so that we don't have uneven changes in your energy levels
              due to different amounts of food intake. We're being nice, here,
              one sleep deprivation study had participants on an IV drip the
              whole time!
            </WhyPopup>
          </p>
        </div>
        <Ui.Divider hidden />
        <div>
          <p>
            <Highlights>
              Are there different experiments I can participate in, instead?
            </Highlights>
          </p>
          <p>
            Yes. If you are ineligible for this study, or want something similar
            but less demanding, you can see other studies we're running here:
          </p>
          <p>
            <a href="./studies">
              <Ui.Button>Other experiments</Ui.Button>
            </a>
          </p>
        </div>
      </div>
    )
  }
];

export default sections;
