import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";
import WhyPopup from "ewoq/components/WhyPopup";
import { Language } from "./misc";
import { En, De, l } from "ewoq/components/Language";

const HeaderContainer = styled.div`
  // height: 3.5em;
  height: auto;
  min-height: 3.5em;
  // font-size: 5vh;
  font-size: 300%;
  padding: 0.5em;
  line-height: 1.2em;
  text-align: center;
`;

const SectionBodySegment = styled.div`
  @media only screen and (min-width: 600px) {
    margin-left: 1em;
    margin-right: 1em;
  }
`;

const SectionBody = styled.div`
  text-align: justify;
  padding: 1em;
`;

const WarningBox = styled.div`
  margin-top: 1em;
`;

const SubText = styled.div`
  font-style: italic;
  margin-bottom: 1em;
  text-align: center;
`;

const LinksArea = styled.div`
  // padding-bottom: 3em;
  margin-top: 1em;
`;

const ButtonArea = styled.div`
  margin: auto;
  text-align: center;
`;

interface State {
  consent: boolean | undefined;
}

interface Props {
  onDone: (s: boolean) => void;
  onChangeLanguage: (l: Language) => void;
  language: Language;
}

class Consent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { consent: undefined };
  }

  onConsentClick = (consent: boolean) => () => this.setState({ consent });

  render() {
    return (
      <div>
        <LinksArea>
          <a href="/">
            <Ui.Button
              content={"Experiment Information"}
              icon="arrow left"
              compact
              size="tiny"
            />
          </a>
        </LinksArea>
        <HeaderContainer>Screening Questionnaire</HeaderContainer>
        <Ui.Container>
          <Ui.Segment>
            <SectionBodySegment>
              <Ui.Divider horizontal>Terms and conditions</Ui.Divider>
              <SectionBody>
                <p>
                  This questionnaire has 5 sections. The first includes simple
                  yes/no questions regarding your health and other sensitive
                  data such as drug use.{" "}
                  <b>
                    Your answers to these first questions will not be saved;
                    this is only an interactive tool to determine if you can
                    participate.
                  </b>
                  <WhyPopup>
                    Since we ask about illegal activity and health related
                    issues, normally we first need written consent from you.
                    Since this questionnaire is all done before meeting you, we
                    can't keep such data, even anonymously.
                  </WhyPopup>
                  The remaining parts of the questionnaire will ask you
                  questions about your sleep, your drinking and smoking habits,
                  and your susceptibility to changes and stress.
                  <WhyPopup>
                    <p>
                      Some questions are uncomfortable but necessary to exclude
                      participants who do not match the correct profile for this
                      experiment.
                    </p>
                    <p>
                      Some questions will be very similar. This is because this
                      questionnaire is a mix of traditional sleep-evaluation
                      measures, and custom questions we're testing for the first
                      time.
                    </p>
                  </WhyPopup>
                  These answers will be saved, and only included in later
                  analyses if you are accepted for the study and sign formal
                  consent.
                </p>
                <p>
                  The whole questionnaire takes 20-30 minutes. If you realize
                  halfway through that you may not be eligible, or you do not
                  feel comfortable sharing all this information, you may leave
                  the questionnaire at any time, no harm done. Data of
                  incomplete questionnaires will be discarded without further
                  analyses.
                </p>
                <p>
                  Nowhere in this questionnaire do we ask for personal
                  identifiable information,
                  <WhyPopup>
                    name, date of birth, contact info; anything that could be
                    used to trace the data to you
                  </WhyPopup>
                  but instead provide you at the end with a code and our contact
                  information, and the data remains completely anonymous. Once
                  you have established a meeting via email, the experiment will
                  be explained again in person, and we will ask for formal
                  written consent. Then, you need only provide us with your
                  code, and we can de-anonymize your data.
                </p>
                <p>
                  <strong>
                    It is very important that you reach the end of the
                    questionnaire and receive your code.
                  </strong>{" "}
                  Otherwise we have no way of knowing which questionnaire is
                  yours, and you'll have to do it again.
                </p>
              </SectionBody>
              <SectionBody>
                <Ui.Segment>
                  <Ui.Divider horizontal>Choose a language</Ui.Divider>
                  <SubText>
                    If you later decide to change language, you will have to
                    start all over again.
                  </SubText>
                  {/* <Ui.Grid centered columns={4}>
                    <Ui.Grid.Column> */}
                  <ButtonArea>
                    <Ui.ButtonGroup>
                      <Ui.Button
                        onClick={() => this.props.onChangeLanguage(Language.en)}
                        color={
                          this.props.language === Language.en
                            ? "teal"
                            : undefined
                        }
                      >
                        <Ui.Flag name="gb" />
                        {/* TODO: make flags bigger, nicer */}
                      </Ui.Button>
                      <Ui.ButtonOr />
                      <Ui.Button
                        onClick={() => this.props.onChangeLanguage(Language.de)}
                        color={
                          this.props.language === Language.de
                            ? "teal"
                            : undefined
                        }
                      >
                        <Ui.Flag name="de" />
                      </Ui.Button>
                    </Ui.ButtonGroup>
                    {/* </Ui.Grid.Column>
                  </Ui.Grid> */}
                  </ButtonArea>
                </Ui.Segment>
              </SectionBody>
              <SectionBody>
                <Ui.Message warning>
                  <Ui.MessageHeader>
                    <En>
                      Do you agree to allow your answers to be saved and used
                      for scientific research?
                    </En>
                    <De>
                      Sind Sie damit einverstanden, dass Ihre Antworten
                      gespeichert und für wissenschaftliche Forschung verwendet
                      werden?
                    </De>
                  </Ui.MessageHeader>
                  <Ui.MessageContent>
                    {
                      <WarningBox>
                        <p>
                          <En>
                            Your identity will in no way be associated with the
                            answers. The data will only become associated with
                            your identity if you choose to come to the lab, sign
                            formal consent, and participate. Otherwise, it will
                            be discarded.
                          </En>
                          <De>
                            Ihre Identität wird in keiner Weise mit den
                            Antworten in Verbindung gebracht. Die Daten werden
                            nur dann mit Ihrer Identität verknüpft, wenn Sie
                            sich dafür entscheiden, zum Labor zu kommen, eine
                            formelle Einwilligung zu unterschreiben und
                            teilzunehmen. Andernfalls werden sie verworfen.
                          </De>
                        </p>
                        <Ui.Form>
                          <Ui.Form.Field>
                            <Ui.Checkbox
                              radio
                              label={l({ en: "Accept", de: "Zustimmen" })}
                              checked={this.state.consent}
                              onClick={this.onConsentClick(true)}
                            />
                          </Ui.Form.Field>
                          <Ui.Form.Field>
                            <Ui.Checkbox
                              radio
                              label={l({
                                en: "Disagree",
                                de: "Nicht zustimmen"
                              })}
                              checked={
                                !this.state.consent &&
                                this.state.consent !== undefined
                              }
                              onClick={this.onConsentClick(false)}
                            />
                          </Ui.Form.Field>
                        </Ui.Form>
                      </WarningBox>
                    }
                  </Ui.MessageContent>
                </Ui.Message>
              </SectionBody>
              <SectionBody>
                <Ui.Button
                  fluid
                  disabled={this.state.consent !== true}
                  onClick={() => this.props.onDone(this.state.consent || false)}
                >
                  {l({ en: "Start", de: "Anfang" })}
                </Ui.Button>
              </SectionBody>
            </SectionBodySegment>
          </Ui.Segment>
        </Ui.Container>
      </div>
    );
  }
}

export default Consent;
