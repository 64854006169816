import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as YesNoQ from "ewoq/prototypes/YesNo";
import * as Input from "ewoq/prototypes/TypeInput";
import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";
import * as Slider from "ewoq/prototypes/Slider";

import * as RepeatGroupQuestions from "ewoq/prototypes/RepeatGroupQuestions";
import { twoHoursDay } from "Questionnaires/StandardQuestionComponents/TimeofDay";

const questions: QuestionType[] = [
  {
    id: "SR_ACT_1",
    mandatory: false,
    title: <span>Yesterday, what major activities did you do?</span>,
    plainTitle: "Major activities:",
    explanation:
      "Please indicate here either how you spent the majority of your time (working, tv, hiking) or if something exceptional happened (near accident, sky diving, won the lottery).",
    component: v => (
      <RepeatGroupQuestions.Questions
        onValid={v}
        subQuestions={[
          {
            id: "what",
            title: "Activity:",
            component: v => <Input.Question onValid={v} inputType="string" />
          },
          { id: "when", title: "Time", component: v => twoHoursDay(v, false) },
          {
            id: "where",
            title: "Where?",
            component: v => (
              <MultipleChoice.Question
                onValid={v}
                options={[
                  { text: "Indoor" },
                  { text: "Outdoor" },
                  { text: "Commuting" }
                ]}
                includeDone={false}
              />
            )
          }
        ]}
      />
    )
  },
  {
    id: "SR_ACT_2",
    mandatory: false,
    title: (
      <span>
        How often did you find yourself procrastinating/distracted while
        working/studying?
      </span>
    ),
    plainTitle: "Proctrastination",
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Never", "All the time"]}
        sliderType={Slider.SliderType.horizontal}
        extraRadio={["I didn't work/study today"]}
      />
    )
  },
  {
    id: "SR_ACT_3",
    plainTitle: "Screen time",
    title: "How much time did you spend in front of a screen today?",
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["None", "1 hour", "5 hours", ">10 hours"]}
      />
    )
  }
];
export default questions;

//TODO: add plain titles and change numbering, and update questions
