import React from "react";
import * as Range from "ewoq/prototypes/Ranges";

export const threeHoursDay = (v: any, includeDone?: boolean) => (
  <Range.Question
    onValid={v}
    includeDone={includeDone}
    labels={[
      "6-9 AM",
      "9AM -12PM",
      "12-3 PM",
      "3-6 PM",
      "6-9 PM",
      "9AM - 12PM"
    ]}
  />
);

export const twoHoursDay = (v: any, includeDone?: boolean) => (
  <Range.Question
    onValid={v}
    includeDone={includeDone}
    labels={[
      "6-8 AM",
      "8-10 AM",
      "10AM -12PM",
      "12-2 PM",
      "2-4 PM",
      "4-6 PM",
      "6-8 PM",
      "8-10 PM",
      "10AM - 12PM"
    ]}
  />
);
