import React from "react";
import Consent from "./Consent";
import SensitiveChecklist from "./SensitiveChecklist";
import Ineligible from "./Ineligible";
import Eligible from "./Eligible";
import PSQI from "./PSQI";
import MCTQ from "./MCTQ";
import Wake from "./Wake";
import Sleep from "./Sleep";
import { Language } from "./misc";
import { LanguageProvider } from "ewoq/components/Language";

// This file controls what is shown during the questionnaires.
// It is used to specify the order of questionnaires, and what
// happens if the person is eligible or ineligible

enum Phase {
  Consent,
  Sensitive,
  PSQI,
  MCTQ,
  Wake,
  Sleep,
  Ineligible,
  Eligible
}

interface Props {}

interface State {
  phase: Phase;
  language: Language;
  eligible: boolean;
}

class Screening extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      phase: Phase.Consent,
      language: Language.en,
      eligible: true
    };
  }

  advancePhase = (eligiblePhase: Phase, ineligiblePhase: Phase) => (
    answer: boolean
  ) => {
    const eligible = this.state.eligible && answer;
    this.setState({
      phase: answer ? eligiblePhase : ineligiblePhase,
      eligible
    });
    console.log("screening ", this.state);
  };

  onLanguageChoice = (language: Language) => this.setState({ language });

  render() {
    return (
      <LanguageProvider value={this.state.language}>
        {this.renderInner()}
      </LanguageProvider>
    );
  }

  renderInner() {
    {
      console.log("screening 2", this.state);
    }
    switch (this.state.phase) {
      case Phase.Consent: {
        return (
          <Consent
            language={this.state.language}
            onChangeLanguage={this.onLanguageChoice}
            onDone={this.advancePhase(Phase.Sensitive, Phase.Consent)}
          />
        );
      }
      case Phase.Sensitive: {
        return (
          <SensitiveChecklist
            onDone={this.advancePhase(Phase.PSQI, Phase.Ineligible)}
          />
        );
      }
      case Phase.PSQI: {
        return <PSQI onDone={this.advancePhase(Phase.MCTQ, Phase.MCTQ)} />;
      }
      case Phase.MCTQ: {
        return <MCTQ onDone={this.advancePhase(Phase.Wake, Phase.Wake)} />;
      }
      case Phase.Wake: {
        return <Wake onDone={this.advancePhase(Phase.Sleep, Phase.Sleep)} />;
      }
      case Phase.Sleep: {
        return (
          <Sleep onDone={this.advancePhase(Phase.Eligible, Phase.Ineligible)} />
        );
      }
      case Phase.Ineligible: {
        return <Ineligible />;
      }

      case Phase.Eligible: {
        return <Eligible />;
      }
    }
  }
}

export default Screening;
