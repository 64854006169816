import React from "react";
import { QuestionType, Question } from "ewoq/containers/Interrogator";
import * as Radio from "ewoq/prototypes/Radio";
import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";
import * as Slider from "ewoq/prototypes/Slider";

const drinkList = [
  { text: "Coffee" },
  { text: "Tea" },
  { text: "Juice" },
  { text: "Milk" },
  { text: "Soft drinks / soda" },
  { text: "Energy drink" },
  { text: "Alcohol", extraInput: "string", placeHolder: "e.g. wine" },
  { text: "Other", extraInput: "string" }
];

const questions: QuestionType[] = [
  {
    id: "LQ_EA2_1",
    mandatory: false,
    plainTitle: "Body like a temple",
    title: (
      <span>
        Do you “treat your body like a temple” and take special care of what you
        consume (eat, drink, smoke, etc)?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Not at all", "Somewhat", "Consistently"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_EA2_2.6",
    mandatory: false,
    plainTitle: "Dinner drinks",
    title: (
      <span>
        Do you drink any of the following at dinner or in the evening regularly
        (at least 3 times per week):{" "}
      </span>
    ),
    component: v => <MultipleChoice.Question onValid={v} options={drinkList} />
  },
  {
    id: "LQ_EA2_3",
    mandatory: false,
    title: <span>What don't you eat: </span>,
    plainTitle: "Foods I don't eat:",
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "Meat" },
          { text: "Fish" },
          { text: "Dairy" },

          { text: "Carbohydrates" },
          { text: "Gluten" },
          { text: "Fats" },
          { text: "Sugar" },
          { text: "Nuts" },
          { text: "Any animal products (vegan diet)" },
          { text: "Cooked food (raw diet)" },
          { text: "Processed food (paleo diet)" },
          { text: "Other", extraInput: "string" }
        ]}
      />
    )
  },
  {
    id: "LQ_EA2_4",
    mandatory: false,
    plainTitle: "Supplements or extra diet?",
    title: (
      <span>
        Are you actively taking supplements or trying to eat more of something?
      </span>
    ),
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: "Yes", extraInput: "string", placeHolder: "e.g. Vitamin D" },
          { text: "No" }
        ]}
      />
    )
  },
  {
    id: "LQ_EA2_5.1",
    mandatory: false,
    plainTitle: "Chocolate consumption",
    title: <span>How much chocolate do you usually eat?</span>,
    explanation:
      "This includes chocolate in cakes, pastries, drinks, everywhere. Give your best estimate. 1 bar is equal to 100g.",
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          "None",
          "1 bar per month",
          "1 bar per week",
          "1 bar per day",
          ">3 bars per day"
        ]}
      />
    )
  },
  {
    id: "LQ_EA2_5.2",
    mandatory: false,
    plainTitle: "Chocolate darkness",
    title: <span>How dark is the chocolate you usually consume?</span>,
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_EA2_5.1");
      return a[idx].data !== undefined && a[idx].data["answer"] > 0.05;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["White chocolate", "50% milk chocolate", "100% dark"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  }
];
export default questions;
