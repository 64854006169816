import React from "react";

import { QuestionType, Question } from "ewoq/containers/Interrogator";
import * as Input from "ewoq/prototypes/TypeInput";
import * as Slider from "ewoq/prototypes/Slider";
import * as SliderGroup from "ewoq/prototypes/SliderGroup";
import * as Radio from "ewoq/prototypes/Radio";
import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";
import * as GroupQuestion from "ewoq/prototypes/GroupQuestions";
import { SubQuestions } from "ewoq/prototypes/GroupQuestions";
import {
  feelings,
  extraFeelings
} from "Questionnaires/StandardQuestionComponents/Feelings";
import { kss } from "Questionnaires/StandardQuestionComponents/KSS";
import { fourEnergiesPresent } from "Questionnaires/StandardQuestionComponents/FourEnergies";

const questions: QuestionType[] = [
  {
    id: "BAT_1",
    mandatory: true,
    plainTitle: "KSS sleepiness between tasks",
    title: <span>Please indicate your sleepiness right now:</span>,
    component: v => kss(v)
  },
  {
    id: "BAT_3",
    plainTitle: "task experience",
    title: <span>How did you experience this task?</span>,
    component: v => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          { labels: ["Frustrating", "Neutral", "Relaxing"] },
          { labels: ["Boring", "Neutral", "Fun / Interesting"] }
        ]}
      />
    )
  },
  {
    id: "BAT_3.1",
    plainTitle: "task focus",
    title: <span>How focused on the task were you?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          "Completely distracted",
          "Somewhat distracted",
          "Pretty focused",
          "Laser focused"
        ]}
      />
    )
  },
  {
    id: "BAT_4",
    plainTitle: "Subjective difficulty",
    title: <span>How hard was it to perform this task?</span>,
    tip: "For music listening, skip",
    mandatory: false,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Very easy", "Very difficult"]}
      />
    )
  },
  {
    id: "BAT_4.1",
    plainTitle: "Subjective effort",
    title: <span>How much effort did you put into performing this task?</span>,
    mandatory: false,
    explanation:
      "Think about how much you tried to do well, and how much more you could have done.",
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["None at all", "some effort", "A lot of effort"]}
      />
    )
  },
  {
    id: "BAT_5",
    mandatory: false,
    plainTitle: "subjective task performance",
    title: <span>How well do you think you did the task:</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Very poorly", "Ok", "Very well"]}
      />
    )
  },

  {
    id: "BAT_7",
    plainTitle: "Fall asleep during task",
    title: <span>Did you fall asleep during the task?</span>,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[{ text: "Yes" }, { text: "No" }, { text: "Not sure" }]}
      />
    )
  },
  {
    id: "BAT_8",
    plainTitle: "Motivation for the task",
    mandatory: false,
    title: <span>How motivated were you during the task?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          "I really did not want to do it",
          "I didn't mind doing it",
          "I was really motivated to do it"
        ]}
      />
    )
  }
];

export default questions;
