import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Radio from "ewoq/prototypes/Radio";
import * as YesNoQ from "ewoq/prototypes/YesNo";
import * as Input from "ewoq/prototypes/TypeInput";
import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";
import * as Slider from "ewoq/prototypes/Slider";
import * as SliderGroup from "ewoq/prototypes/SliderGroup";
import { Answer } from "ewoq/prototypes/GroupQuestions";
import { activities } from "Questionnaires/StandardQuestionComponents/Miscelanous";

const questions: QuestionType[] = [
  {
    id: "SR_SLE_1.1",
    mandatory: true,
    plainTitle: "Sleep quality:",
    title: <span>How was your sleep?</span>,
    component: v => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          { labels: ["Terrible", "Standard", "Perfect"] },
          { labels: ["Superficial", "Deep"] },
          { labels: ["Fragmented", "Continuous"] },
          { labels: ["Restless", "Calm"] }
        ]}
      />
    )
  },
  {
    id: "SR_SLE_1.2",
    mandatory: true,
    plainTitle: "Current state: ",
    title: <span>How do you feel right now?</span>,
    component: v => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          { labels: ["Tired", "Refreshed"] },
          { labels: ["In a bad mood", "In a good mood"] },
          { labels: ["Apathetic", "Full of energy"] },
          { labels: ["Restless / tense", "Calm"] },
          { labels: ["Unfocused", "Concentrated"] }
        ]}
      />
    )
  },
  {
    id: "SR_SLE_2.1",
    mandatory: true,
    title: <span>How was your sleep environment?</span>,
    plainTitle: "Sleep environment:",
    component: v => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          { labels: ["Comfortable", "Uncomfortable"] },
          { labels: ["Too quiet", "Perfect", "Too noisy"] },
          { labels: ["Too cold", "Perfect", "Too warm"] },
          { labels: ["Too dark", "Perfect", "Too bright"] }
        ]}
      />
    )
  },
  {
    id: "SR_SLE_2.2",
    mandatory: false,
    plainTitle: "usual bed?",
    title: <span>Did you sleep in your usual bed?</span>,
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_SLE_3.1",
    title: <span>Did anything disturb your sleep?</span>,
    plainTitle: "Sleep disturbances:",
    explanation: (
      <span>Did you notice something unusual (smells, sounds, etc)?</span>
    ),
    component: v => (
      <Input.Question
        onValid={v}
        inputType="string"
        placeholder="e.g. people talking"
        extraRadio={["No", "Maybe, but I don't know what"]}
      />
    )
  },
  {
    id: "SR_SLE_3.2",
    mandatory: false,
    plainTitle: "sleep alone?",
    title: <span>Were you sleeping alone?</span>,
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_SLE_3.3",
    mandatory: false,
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_SLE_3.2");
      return (
        a[idx].data !== undefined &&
        a[idx].data["answer"] === YesNoQ.AnswerType.no
      );
    },
    title: <span>Did the other person influence your sleep?</span>,
    plainTitle: "influence of other person:",
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Better", "No difference", "Worse"]}
      />
    )
  },
  {
    id: "SR_SLE_4.1",
    mandatory: true,
    plainTitle: "enter bed time",
    title: <span>When did you enter the bed last night?</span>,
    component: v => <Input.Question onValid={v} inputType="time" />
  },
  {
    id: "SR_SLE_4.2",
    mandatory: true,
    plainTitle: "lights off time",
    title: (
      <span>
        At what time did you turn the lights off/put your phone down to go to
        sleep?
      </span>
    ),
    component: v => <Input.Question onValid={v} inputType="time" />
  },
  {
    id: "SR_SLE_4.3",
    mandatory: true,
    plainTitle: "what kept you awake?",
    title: (
      <span>
        If this was later than anticipated, what kept you awake longer than
        planned?
      </span>
    ),
    component: v => (
      <Input.Question
        onValid={v}
        inputType="string"
        extraRadio={["Nothing kept me awake"]}
      />
    )
  },
  {
    id: "SR_SLE_4.4",
    mandatory: true,
    plainTitle: "Time to fall asleep:",
    title: <span>How long did it take you to fall asleep, approximately?</span>,
    component: v => (
      <Input.Question onValid={v} inputType="number" placeholder="Minutes" />
    )
  },
  {
    id: "SR_SLE_5.1",
    mandatory: true,
    title: <span>How easy was it for you to fall asleep?</span>,
    plainTitle: "Ease of falling asleep:",
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Extremely hard", "Extremely easy"]}
      />
    )
  },
  {
    id: "SR_SLE_5.2",
    mandatory: false,
    plainTitle: "Causes for difficulty falling asleep:",
    title: (
      <span>
        Did any of the following make it difficult to fall asleep once you
        turned the lights off?
      </span>
    ),
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_SLE_5.1");
      return a[idx].data !== undefined && a[idx].data["answer"] < 0.5;
    },
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          {
            text:
              "Ruminating thoughts (couldn’t stop thinking about something over and over)"
          },
          { text: "Anxiety" },
          { text: "Excitement" },
          { text: "Not optimal sleep environment" },
          { text: "Not tired enough" },
          { text: "Something, but I don't know what" },
          { text: "Other", extraInput: "string" }
        ]}
      />
    )
  },
  {
    id: "SR_SLE_6.1",
    mandatory: true,
    plainTitle: "Pre sleep activities",
    title: (
      <span>Did you do any of the following just before ({"<"}2h) sleep?</span>
    ),
    component: v => activities(v)
  },
  {
    id: "SR_SLE_6.2",
    mandatory: false,
    title: <span>Which medium did you use to read?</span>,
    plainTitle: "reading medium",
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_SLE_6.1");
      return a[idx].data !== undefined && a[idx].data["answers"][0];
    },
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "Paper" },
          { text: "LED screen (e.g. iPad)" },
          { text: "E-ink reader (e.g. kindle)" },
          { text: "Backlit e-ink reader (e.g. kindle paperwhite)" }
        ]}
      />
    )
  },
  {
    id: "SR_SLE_6.3",
    mandatory: false,
    title: <span>Did you use your phone while in bed before sleep?</span>,
    plainTitle: "Phone use pre sleep",
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_SLE_6.5",
    mandatory: false,
    title: (
      <span>
        Did you do any sexual activities before ({"<"}3h) going to sleep?
      </span>
    ),
    plainTitle: "sex pre sleep",
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_SLE_7",
    mandatory: true,
    title: (
      <span>Did you do any of the following to improve sleep quality:</span>
    ),
    plainTitle: "improve sleep methods",
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "Maintain a certain room temperature" },
          {
            text: "Maintain constant darkness (wear a face mask/lower shades)"
          },
          { text: "Use earplugs" },
          { text: "Eat / drink / take something", extraInput: "string" },
          { text: "Other", extraInput: "string" }
        ]}
      />
    )
  },
  {
    id: "SR_SLE_8.1",
    mandatory: true,
    plainTitle: "Waking in the night:",
    title: <span>Did you wake up in the middle of the night?</span>,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: "Never" },
          { text: "Once or twice" },
          { text: "Several times" },
          { text: "I barely slept" }
        ]}
      />
    )
  },
  {
    id: "SR_SLE_8.2",
    mandatory: false,
    plainTitle: "Confidence in waking at night:",
    title: <span>How sure are you about waking up in the night?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["0%", "100% sure"]}
      />
    )
  },
  {
    id: "SR_SLE_8.3",
    mandatory: true,
    title: <span>Did you get out of bed?</span>,
    plainTitle: "get out of bed",
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_SLE_8.1");
      return a[idx].data !== undefined && a[idx].data["answer"] !== 0;
    },
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_SLE_8.4",
    mandatory: false,
    title: <span>Did you wake up thirsty?</span>,
    plainTitle: "Wake up thirsty?",
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_SLE_8.1");
      return a[idx].data !== undefined && a[idx].data["answer"] !== 0;
    },
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_SLE_8.5",
    mandatory: false,
    title: <span>Did you wake up hungry?</span>,
    plainTitle: "Wake up hungry?",
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_SLE_8.1");
      return a[idx].data !== undefined && a[idx].data["answer"] !== 0;
    },
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_SLE_8.6",
    mandatory: false,
    title: <span>Did you wake up to go to the toilet?</span>,
    plainTitle: "wakeup for toilet",
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_SLE_8.1");
      return a[idx].data !== undefined && a[idx].data["answer"] !== 0;
    },
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_SLE_9.1",
    mandatory: true,
    plainTitle: "when wakeup",
    title: <span>When did you wake up in the morning?</span>,
    component: v => <Input.Question onValid={v} inputType="time" />
  },
  {
    id: "SR_SLE_10.1",
    mandatory: true,
    plainTitle: "wake up with alarm",
    title: <span>Did you wake up with the alarm?</span>,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: "Yes" },
          {
            text: "I was already awake before the alarm went off"
          },

          {
            text:
              "Yes, but I had already woken up before and gone back to sleep"
          },
          { text: "Yes, but hitting snooze / with more than one alarm" },
          {
            text: "Yes, but then I went back to sleep and woke up spontaneously"
          },
          { text: "No, I slept through the alarm" }
        ]}
      />
    )
  },

  {
    id: "SR_SLE_11",
    mandatory: true,
    title: <span>How easy was it for you to wake up?</span>,
    plainTitle: "Ease of waking up:",
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Extremely easy", "Extremely hard"]}
      />
    )
  },
  {
    id: "SR_SLE_12",
    mandatory: false,
    title: <span>Did you wake up with any aches or pain?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["I feel perfectly fine", "A few aches", "My whole body aches"]}
      />
    )
  }
];
export default questions;

//TODO: add plain titles and change numbering, and update questions
