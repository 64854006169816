import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";
import { getUserId } from "ewoq/utils";

const HeaderContainer = styled.div`
  height: 4em;
  font-size: 2em;
  padding: 0.5em;
  line-height: 1.2em;
  text-align: center;
`;
const SectionBody = styled.p`
  text-align: justify;
  margin-bottom: 3em;
  margin-top: 1em;
`;
const Smiley = styled.span`
  font-size: 1em;
`;
const CodeText = styled.div`
  font-size: 1.5em;
  text-transform: uppercase;
  font-style: bold;
  text-align: center;
  margin: 0.5em;
`;

class Ineligible extends React.Component {
  render() {
    return (
      <div>
        <HeaderContainer>
          Unfortunately, you don't meet all the screening criteria for this
          experiment <Smiley>☹️</Smiley>
        </HeaderContainer>
        <Ui.Container>
          <Ui.Segment>
            <SectionBody>
              <p>
                However, we are conducting similar sleep studies all the time,
                with different screening criteria. If you wish to learn more
                about other opportinities, you can click on the button below, or
                you may contact me at: later_experiments@sophia.science, and I
                will keep your email to contact you about future studies that
                have less stringent criteria.
              </p>
              <p>
                <a href="./studies">
                  <Ui.Button size="large" fluid color="violet">
                    Other ongoing experiments
                  </Ui.Button>{" "}
                </a>
              </p>
            </SectionBody>
          </Ui.Segment>
          <Ui.Segment>
            <div>
              If you think there may have been a mistake, or are participating
              in the piloting of this study, please email
              sophia.snipes@kispi.uzh.ch, and provide her with the first word of
              the following code:
            </div>
            <CodeText>{getUserId()}</CodeText>
            <div>Save this code for future reference, but keep it secret.</div>
          </Ui.Segment>
        </Ui.Container>
        <Ui.Container>
          <a href="/">
            <SectionBody>
              <Ui.Button
                content={"Experiment Information"}
                icon="arrow left"
                compact
                size="tiny"
              />
            </SectionBody>
          </a>
        </Ui.Container>
      </div>
    );
  }
}

export default Ineligible;
