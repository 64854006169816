import React from "react";
import { Interrogator, QuestionsObj } from "ewoq/containers/Interrogator";
import questions from "./Questions/PSQIQuestions";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";
import PSQIscore from "./PSQIscorer";

import { En, De, l } from "ewoq/components/Language";
import { answerQuestionnaire } from "ewoq/api";

const MessageText = styled.div`
  text-align: justify;
`;

interface Props {
  onDone: (s: boolean) => void;
  viewAll?: boolean;
}

class PSQI extends React.Component<Props> {
  onSubmit = (answers: QuestionsObj) => {
    const scores = PSQIscore(answers);
    let isEligible = Object.values(answers).every(a => a.isOk);
    isEligible = isEligible && scores["globalScore"] <= 5;
    console.log("PSQI score is: ", scores);
    answerQuestionnaire("LSM", "Screening-PSQI", {
      answers: answers,
      scores: scores,
      isEligible: isEligible
    });
    this.props.onDone(isEligible);
  };

  render() {
    return (
      <div>
        <Ui.Message warning>
          <MessageText>
            <Ui.MessageHeader>
              <En>
                Your answers will from now on be saved as soon as you click
                "submit" at the end.
              </En>
              <De>
                Ihre Anworten werden von jetzt an gespeichert, sobald Sie am
                Ende auf submit klicken.
              </De>
            </Ui.MessageHeader>
            <Ui.MessageContent>
              <p></p>
              <En>
                <p>
                  Your progress is saved so long as you keep this tab open. If
                  you try to go back to a previous page, your progress will be
                  lost. If you made a mistake, it is possible to correct this in
                  person.
                </p>
                <p>
                  If you don't know the answer exactly, give your best guess. If
                  you have access to exact answers from a fitness tracker or
                  your phone, that would be lovely.
                </p>
              </En>
              <De>
                {/*TODOe */}
                <p>
                  So lange Sie den Browser geöffnet lassen, bleiben Ihre
                  Antworten auf Ihrem Computer. Sobald Sie den Browser
                  schliessen, wird es nicht mehr möglich sein Ihre Antworten
                  wiederherzustellen, ausser Sie kommen bis zum Ende und
                  erhalten den 3-Worte-Code.
                </p>
                <p>
                  Falls Sie die Antoworten nicht ganz genau wissen, geben Sie
                  eine Schätzung. Falls Sie Zugang zu genauen Anworten von einem
                  Fitnesstracker oder von Ihrem Handy haben, wäre das
                  hervorragend.
                </p>
              </De>
            </Ui.MessageContent>
          </MessageText>
        </Ui.Message>
        <Interrogator
          questions={questions}
          onDone={s => this.onSubmit(s)}
          viewAll={this.props.viewAll}
        />
      </div>
    );
  }
}

export default PSQI;
