import React from "react";
import Consent from "./Consent";
import SensitiveChecklist from "./SensitiveChecklist";
import Eligible from "./EligiblePilot";
import PSQI from "./PSQI";
import MCTQ from "./MCTQ";
import Wake from "./Wake";
import Sleep from "./Sleep";
import { Language } from "./misc";
import { LanguageProvider } from "ewoq/components/Language";

import * as Ui from "semantic-ui-react";

enum Phase {
  Consent,
  Sensitive,
  PSQI,
  MCTQ,
  Wake,
  Sleep, // -> NotEligible, ThrutfulnessCheck
  Ineligible, // -> stop
  ThrutfulnessCheck, // -> Eligible
  Eligible // -> stop
}

interface Props {}

interface State {
  phase: Phase;
  language: Language;
}

class Screening extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { phase: Phase.Consent, language: Language.en };
  }

  advancePhase = (eligible: Phase) => (answer: boolean) => {
    this.setState({ phase: answer ? eligible : eligible });
  };

  onLanguageChoice = (language: Language) => this.setState({ language });

  render() {
    return (
      <LanguageProvider value={this.state.language}>
        {this.renderInner()}
      </LanguageProvider>
    );
  }

  renderInner() {
    switch (this.state.phase) {
      case Phase.Consent: {
        return (
          <div>
            <Ui.Message warning>
              <Ui.Message.Header>This is still a pilot study</Ui.Message.Header>
              <Ui.Message.Content>
                After completing the screening questionnaire, you will receive
                an evaluation via email whether you can actually participate.
                Some things may still not work properly, and if you think
                anything needs fixing, or a question wasn't clear, we would
                greatly appreciate you telling us.
              </Ui.Message.Content>
            </Ui.Message>
            <Consent
              language={this.state.language}
              onChangeLanguage={this.onLanguageChoice}
              onDone={this.advancePhase(Phase.Sensitive)}
            />
          </div>
        );
      }
      case Phase.Sensitive: {
        return <SensitiveChecklist onDone={this.advancePhase(Phase.PSQI)} />;
      }
      case Phase.PSQI: {
        return <PSQI onDone={this.advancePhase(Phase.MCTQ)} />;
      }
      case Phase.MCTQ: {
        return <MCTQ onDone={this.advancePhase(Phase.Wake)} />;
      }
      case Phase.Wake: {
        return <Wake onDone={this.advancePhase(Phase.Sleep)} />;
      }
      case Phase.Sleep: {
        return <Sleep onDone={this.advancePhase(Phase.Eligible)} />;
      }

      case Phase.Eligible: {
        return <Eligible />;
      }
    }

    return <p>a</p>;
  }
}

export default Screening;
