import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";
import { answerQuestionnaire } from "ewoq/api";
import { getUserId } from "ewoq/utils";
import { Squeeze } from "ewoq/components/miscStyles";

const HeaderContainer = styled.div`
  height: auto;
  min-height: 3.5em;

  font-size: 300%;
  padding: 0.5em;
  line-height: 1.2em;
  text-align: center;
`;

const SectionBody = styled.div`
  text-align: justify;
  padding: 1em;
`;

const CodeText = styled.span`
  font-size: 1.5em;
  text-transform: uppercase;
  font-style: bold;
`;

const TextQ = styled.p`
  font-style: bold;
  font-size: 1.1em;
  text-align: justify;
`;

const Email = styled.p`
  font-style: bold;
  font-size: 1.2em;
  text-align: center;
`;

interface Props {}

interface State {
  answer: number | undefined;
  modalOpen: boolean;
}

class Eligible extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { answer: undefined, modalOpen: true }; //TODO: make this state get saved to disk
  }

  onSubmit = () => () => {
    this.setState({ modalOpen: false });
    if (this.state.answer === 0) {
      window.location.replace("./");
    }
    answerQuestionnaire("LSM", "Screening-Metadata", {
      isHonest: this.state.answer,
      language: (window as any).currentLanguage
    }); //TODO, add language of state here?
  };

  render() {
    return (
      <div>
        <Ui.Modal closeOnDimmerClick={false} open={this.state.modalOpen}>
          <Ui.Modal.Content>
            <TextQ>
              We are a scientific research lab operating on a budget and
              dedicated to producing the highest quality research possible.
              Intentionally incorrect or careless answers will lead to
              unintentionally false results. Science depends on you being
              truthful and thorough.
            </TextQ>
            <Squeeze>
              <Ui.Segment color="violet">
                <Ui.Form>
                  <Ui.Header as="h3">Were your answers accurate?</Ui.Header>
                  <Ui.Form.Field>
                    <Ui.Radio
                      radio
                      label="Please discard my questionnaire (for whatever reason)"
                      checked={this.state.answer === 0}
                      onClick={() => this.setState({ answer: 0 })}
                    />
                  </Ui.Form.Field>
                  <Ui.Form.Field>
                    <Ui.Radio
                      radio
                      label="I’m not really sure about how accurate my answers were"
                      checked={this.state.answer === 1}
                      onClick={() => this.setState({ answer: 1 })}
                    />
                  </Ui.Form.Field>
                  <Ui.Form.Field>
                    <Ui.Radio
                      radio
                      label="I may have made mistakes, but they were unintentional and would be happy to correct them"
                      checked={this.state.answer === 2}
                      onClick={() => this.setState({ answer: 2 })}
                    />
                  </Ui.Form.Field>
                  <Ui.Form.Field>
                    <Ui.Radio
                      radio
                      label="To the best of my knowledge, all the answers provided are correct"
                      checked={this.state.answer === 3}
                      onClick={() => this.setState({ answer: 3 })}
                    />
                  </Ui.Form.Field>

                  <Ui.Form.Field>
                    <Ui.Button
                      disabled={this.state.answer === undefined}
                      color="green"
                      onClick={this.onSubmit()}
                    >
                      Confirm
                    </Ui.Button>
                  </Ui.Form.Field>
                </Ui.Form>
              </Ui.Segment>
            </Squeeze>
          </Ui.Modal.Content>
        </Ui.Modal>
        <HeaderContainer>You are applicable for our study!</HeaderContainer>
        <Ui.Container>
          <Ui.Segment>
            <SectionBody>
              <p>
                If you are still interested in participating, please email Elias
                Meier to set an appointment:{" "}
              </p>
              <Email>eliaspeter.meier@uzh.ch</Email>

              <p>Please remember or write down this code:</p>
              <Squeeze>
                <Ui.Segment textAlign="center" raised inverted color="violet">
                  <CodeText>{getUserId()}</CodeText>
                </Ui.Segment>
              </Squeeze>
              <Ui.Message warning>
                Do not email this code to the experimenter or share it with
                anyone. If you lose it, you will have to conduct the screening
                questionnaire again.
              </Ui.Message>
            </SectionBody>
          </Ui.Segment>
          <Ui.Segment>
            <Ui.Divider horizontal>Now what</Ui.Divider>
            <SectionBody>
              For this experiment, we want to ensure a relatively uneventful
              sleep-wake rhythm 1 month prior to the experiment day. Please make
              sure that none of the following occur in the <u>month</u> before
              the main experiment, and if they do, please reschedule:
              <Squeeze>
                <Ui.Segment color="orange">
                  <ul>
                    <li>
                      Take any drugs or medication, including marijuana, allergy
                      drugs, etc.
                    </li>
                    <li>Smoke, vape or use nicotine patches</li>
                    <li>
                      Get sick (e.g. flu) or seriously injured (e.g. broken arm)
                    </li>
                    <li>Travel across 2 or more time zones</li>
                    <li>
                      5 or more consecutive days of poor sleep for whatever
                      reason
                    </li>
                    <li>
                      Sleep deprivation (staying awake longer than 20 hours)
                    </li>
                    <li>
                      Sleep restriction (sleeping less than 6 hours a night for
                      at least 3 consecutive nights)
                    </li>
                  </ul>
                </Ui.Segment>
              </Squeeze>
              <Ui.Divider hidden />
              Please make sure to reschedule if any of the following happen in
              the <u>week</u> before the experiment:
              <Squeeze>
                <Ui.Segment color="yellow">
                  <ul>
                    <li>
                      A cold or similar small illness (can still go to work)
                    </li>
                    <li>Vaccine (4-5 days after injection)</li>
                    <li>Menstruation</li>
                    <li>Exam</li>
                    <li>Music festival or other exhausting event</li>
                    <li>Long trip</li>
                    <li>Terrible sleep for one night</li>
                  </ul>
                </Ui.Segment>
              </Squeeze>
            </SectionBody>
          </Ui.Segment>
        </Ui.Container>
      </div>
    );
  }
}

export default Eligible;
