import React from "react";
import { Interrogator, QuestionsObj } from "ewoq/containers/Interrogator";
import questions from "./Questions/Eating1Questions";
import { answerQuestionnaire } from "ewoq/api";

interface Props {
  viewAll?: boolean;
  onDone?: () => void;
}

class Eating1 extends React.Component<Props> {
  onSubmit = (answers: QuestionsObj) => {
    answerQuestionnaire("LSM", "Lifestyle1-Eating", { answers: answers });
    this.props.onDone && this.props.onDone();
  };

  render() {
    return (
      <div>
        <Interrogator
          questions={questions}
          onDone={this.onSubmit}
          viewAll={this.props.viewAll}
        />
      </div>
    );
  }
}

export default Eating1;
