import React from "react";
import { QuestionType, Question } from "ewoq/containers/Interrogator";
import * as YesNoQ from "ewoq/prototypes/YesNo";
import * as Input from "ewoq/prototypes/TypeInput";
import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";
import * as Slider from "ewoq/prototypes/Slider";
import * as SliderGroup from "ewoq/prototypes/SliderGroup";
import * as GroupQuestions from "ewoq/prototypes/GroupQuestions";

const questions: QuestionType[] = [
  {
    id: "LQ_LI1_1.1",
    mandatory: true,
    plainTitle: "Watch tv?",
    title: <span>Do you watch TV or movies?</span>,
    explanation: (
      <span>
        This includes watching TV episodes in streaming, or even YouTube
        channels that have videos lasting more than 20 minutes.
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "LQ_LI1_1.2",
    mandatory: false,
    plainTitle: "TV questions",
    title: <span>Regarding TV or movies...</span>,
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_LI1_1.1");
      return (
        a[idx].data !== undefined &&
        a[idx].data["answer"] === YesNoQ.AnswerType.yes
      );
    },
    component: v => (
      <GroupQuestions.Questions
        onValid={v}
        subQuestions={[
          {
            id: "LQ_LI1_1.2.1",
            title: "How often do you watch?",
            component: v => (
              <Slider.Question
                onValid={v}
                labels={["Never", "Monthly", "Weekly", "Daily"]}
                sliderType={Slider.SliderType.horizontal}
              />
            )
          },
          {
            id: "LQ_LI1_1.2.2",
            title: "For how long per day?",
            component: v => (
              <Slider.Question
                onValid={v}
                sliderType={Slider.SliderType.horizontal}
                labels={["None", "1 hour", "5 hours", ">10 hours"]}
              />
            )
          },
          {
            id: "LQ_LI1_1.2.3",
            title: "How much do you enjoy it?",
            component: v => (
              <Slider.Question
                onValid={v}
                labels={[
                  "I hate it",
                  "I dislike it",
                  "Neutral",
                  "I like it",
                  "I love it"
                ]}
                sliderType={Slider.SliderType.horizontal}
              />
            )
          }
        ]}
      />
    )
  },
  {
    id: "LQ_LI1_2",
    title: "How often do you 'binge watch' a series?",
    plainTitle: "Binge watching frequnecy",
    explanation:
      "This means you watch episodes one after the other, at least 3 in a row.",
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Never", "Yearly", "Monthly", "Weekly", "Daily"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_LI1_2.6",
    title: <span>How much do you enjoy binge watching TV?</span>,
    plainTitle: "Enjoy binge watching?",
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_LI1_2");
      return a[idx].data !== undefined && a[idx].data["answer"] > 0.1;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Not at all", "Neutral", "I like it", "I love it"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  }, //TODO maybe put these two questions into a group
  {
    id: "LQ_LI1_4.1",
    mandatory: false,
    plainTitle: "Science experiment participation",
    title: <span>Do you regularly participate in science experiments?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Never", "Yearly", "Monthly", "Weekly", "Daily"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_LI1_4.2",
    mandatory: true,
    plainTitle: "Overnight sleep",
    title: (
      <span>
        Have you ever participated in an overnight sleep experiment before?
      </span>
    ),
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_LI1_4.1");
      return a[idx].data !== undefined && a[idx].data["answer"] > 0.05;
    },
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "LQ_LI1_4.3",
    mandatory: true,
    plainTitle: "Previous sleep experiment",
    title: (
      <span>
        Have you participated in a sleep experiment in the past month?
      </span>
    ),
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_LI1_4.2");
      return (
        a[idx].data !== undefined &&
        a[idx].data["answer"] === YesNoQ.AnswerType.yes
      );
    },
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "LQ_LI1_5",
    mandatory: false,
    plainTitle: "Urban or rural home?",
    title: <span>Do you currently live in a rural or urban environment?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Completely isolated countryside", "Suburbs", "City center"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_LI1_6",
    mandatory: false,
    plainTitle: "Noise from bedroom",
    title: <span>Can you hear the following from your bedroom at night:</span>,
    component: v => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          { question: "Car traffic: ", labels: ["Not at all", "A lot"] },
          { question: "People/city life: " },
          { question: "Trains/planes: " },
          {
            question: "Water (river, waterfall): "
          },
          { question: "Church bells" },
          { question: "Other" }
        ]}
      />
    )
  },
  {
    id: "LQ_LI1_7",
    mandatory: false,
    plainTitle: "Current weekly obbligations",
    title: "Current weekly obbligations",
    component: v => (
      <GroupQuestions.Questions
        onValid={v}
        subQuestions={[
          {
            id: "LQ_LI1_7.1",
            title: "Do you currently weekly:",
            component: v => (
              <MultipleChoice.Question
                onValid={v}
                options={[
                  { text: "Go to school/take classes" },
                  { text: "Work" },
                  { text: "Military service" },
                  { text: "Other", extraInput: "string" },
                  { text: "No scheduled obbligations" }
                ]}
                includeDone={false}
              />
            )
          },
          {
            id: "LQ_LI1_7.2",

            title: "How many days per week?",
            component: v => (
              <Input.Question
                onValid={v}
                inputType="number"
                placeholder="days"
              />
            )
          },
          {
            id: "LQ_LI1_7.3",

            title: "How many hours per week?",
            component: v => (
              <Input.Question
                onValid={v}
                inputType="number"
                placeholder="hours"
              />
            )
          }
        ]}
      />
    )
  },

  {
    id: "LQ_LI1_8.1",
    mandatory: false,
    plainTitle: "Commute duration",
    title: (
      <span>Every day you regularly go out, how long is your commute?</span>
    ),
    explanation:
      "Give the sum of all your commuting time to and from your various destinations.",
    component: v => (
      <Input.Question onValid={v} inputType="number" placeholder="minutes" />
    )
  },
  {
    id: "LQ_LI1_8.2",
    mandatory: false,
    plainTitle: "Commute mode",
    title: <span>How do you travel?</span>,
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "Car" },
          { text: "Train" },
          { text: "Bus" },
          { text: "Bike/scooter" },
          { text: "eBike/escooter" },
          { text: "Walk" },
          { text: "Other", extraInput: "string" }
        ]}
      />
    )
  }
];

export default questions;
