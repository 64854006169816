import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Radio from "ewoq/prototypes/Radio";
import * as Slider from "ewoq/prototypes/Slider";
import {
  feelings,
  extraFeelings
} from "Questionnaires/StandardQuestionComponents/Feelings";

const questions: QuestionType[] = [
  {
    id: "SR_FEE_1.1",
    mandatory: true,
    plainTitle: "Yesterday's mood:",
    title: <span>Please indicate overall your mood over the past day:</span>,
    component: v => feelings(v)
  },
  {
    id: "SR_FEE_1.2",
    mandatory: true,
    title: "Did you feel...",
    plainTitle: "Yesterday's stress:",
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Relaxed", "Tense", "Stressed"]}
      />
    )
  },
  {
    id: "SR_FEE_1.3",
    mandatory: false,
    plainTitle: "Extra feelings yesterday:",
    title: (
      <span>Check all that characterize a significant amount of your day:</span>
    ),
    component: v => extraFeelings(v)
  },
  {
    id: "SR_FEE_2",
    mandatory: false,
    plainTitle: "Yesterday's positiveness:",
    title: <span>Generally, was it a good day or bad day?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Extremely negative", "Neutral", "Extremely positive"]}
      />
    )
  },
  {
    id: "SR_FEE_3",
    mandatory: true,
    plainTitle: "Yesterday's pain/sickness:",
    title: <span>Did you experience any pain or sickness yesterday?</span>,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: "Not at all" },
          { text: "Not sure / didn't notice" },
          { text: "Something at some point, but it went away quickly" },
          { text: "Yes, I felt a little pain or sickness" },
          { text: "I felt some pain or sickness" },
          { text: "I felt substantial pain or sickness" }
        ]}
      />
    )
  },
  {
    id: "SR_FEE_4",
    mandatory: true,
    plainTitle: "Take any over the counter drugs?",
    title: (
      <span>Did you take any over the counter drugs (e.g. ibuprofen)?</span>
    ),
    component: v => (
      <Radio.Question
        onValid={v}
        options={[{ text: "Yes", extraInput: "string" }, { text: "No" }]}
      />
    )
  }
];
export default questions;
