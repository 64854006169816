import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Radio from "ewoq/prototypes/Radio";
import * as Slider from "ewoq/prototypes/Slider";
import { kss } from "../../StandardQuestionComponents/KSS";
import { fourEnergiesPresent } from "Questionnaires/StandardQuestionComponents/FourEnergies";

const questions: QuestionType[] = [
  {
    id: "SR_CS_1",
    mandatory: true,
    plainTitle: "KSS sleepiness:",
    title: <span>Please indicate your sleepiness right now:</span>,
    component: v => kss(v)
  },
  {
    id: "SR_CS_2",
    plainTitle: "Energy levels:",
    title: <span>How do you feel: </span>,
    component: v => fourEnergiesPresent(v)
  },
  {
    id: "SR_CS_3",
    mandatory: false,
    plainTitle: "Alertness:",
    title: (
      <span>
        How alert are you? How quickly do you feel you would respond to
        something (e.g. a ball thrown at your head)?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Slow, sluggish", "Normal", "Hyperalert"]}
      />
    )
  },
  {
    id: "SR_CS_4",
    mandatory: false,
    plainTitle: "Sleep pressure:",
    title: <span>How much do you want to go to sleep right now?</span>,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: "I can't sleep right now" },
          { text: "I would have a hard time falling asleep" },
          { text: "I might sleep" },
          { text: "I would like to sleep" },
          { text: "I really want to sleep" },
          { text: "This is the most I've ever wanted to sleep" },
          { text: "I feel really tired, but I don't think I could sleep" },
          { text: "Other: ", extraInput: "string" }
        ]}
      />
    )
  },
  {
    id: "SR_CS_5",
    plainTitle: "Focus:",
    title: <span>How focused / concentrated are you at the moment?</span>,
    explanation:
      "Imagine you are in a lecture, how capable would you be of paying attention?",
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Very unfocused", "Very concentrated"]}
      />
    )
  }
];
export default questions;

//TODO: add plain titles and change numbering, and update questions
