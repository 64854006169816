import React from "react";
import * as SliderGroup from "ewoq/prototypes/SliderGroup";

import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";

import styled from "styled-components";
const Smiley = styled.span`
  font-size: 2em;
`;

export const feelings = (v: any) => (
  <SliderGroup.Question
    onValid={v}
    subQuestions={[
      {
        // question: (
        //   <Smiley>
        //     <Ui.Image src={Happy} />
        //   </Smiley>
        // ),
        labels: ["Not at all", "Happy", "Cheerful"]
      },
      {
        // question: (
        //   <Smiley>
        //     <Ui.Image src={Sad} />
        //   </Smiley>
        // ),
        labels: ["Not at all", "Sad", "Crying"]
      },
      {
        // question: (
        //   <Smiley>
        //     <Ui.Image src={Angry} />
        //   </Smiley>
        // ),
        labels: ["Not at all", "Irritated", "Angry"]
      },
      {
        // question: (
        //   <Smiley>
        //     <Ui.Image src={Afraid} />
        //   </Smiley>
        // ),
        labels: ["Not at all", "Concerned/Nervous", "Afraid"]
      }
    ]}
  />
);

export const extraFeelings = (v: any) => (
  <MultipleChoice.Question
    onValid={v}
    options={[
      { text: "Busy" },
      { text: "Frustrated" },
      { text: "Embarrassed" },
      { text: "Confused" },
      { text: "Disgusted" },
      { text: "Bored" },
      { text: "Lonely" },
      { text: "Other", extraInput: "string" }
    ]}
  />
);
