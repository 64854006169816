import React from "react";
import { QuestionType, Question } from "ewoq/containers/Interrogator";
import * as YesNoQ from "ewoq/prototypes/YesNo";
import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";
import * as Slider from "ewoq/prototypes/Slider";

const questions: QuestionType[] = [
  {
    id: "LQ_DIS_1.2",
    mandatory: false,
    plainTitle: "English level:",
    title: <span>How good is your English?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Terrible", "Average", "Native Speaker"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_DIS_2",
    mandatory: true,
    plainTitle: "Expected difficulty for regular bed time",
    title: (
      <span>
        How difficult do you think it will be for you to go to sleep at the same
        time every day for the next week?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Extremely easy", "Extremely hard"]}
        sliderType={Slider.SliderType.horizontal}
        extraRadio={["I don't know"]}
      />
    )
  },
  {
    id: "LQ_DIS_3",
    mandatory: true,
    plainTitle: "Expected difficulty for regular wake up",
    title: (
      <span>
        How difficult do you think it will be for you to wake up at the same
        time every day for the next week (with an alarm clock)?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Extremely easy", "Extremely hard"]}
        sliderType={Slider.SliderType.horizontal}
        extraRadio={["I don't know"]}
      />
    )
  },
  {
    id: "LQ_DIS_4",
    mandatory: true,
    plainTitle: "Expected difficulty for reduced caffeine",
    title: (
      <span>
        How difficult do you think it will be for you to have reduced caffeine
        consumption for the next week?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Extremely easy", "Extremely hard"]}
        sliderType={Slider.SliderType.horizontal}
        extraRadio={["I don't know"]}
      />
    )
  },
  {
    id: "LQ_DIS_5",
    mandatory: true,
    plainTitle: "Expected difficulty for reduced alcohol",
    title: (
      <span>
        How difficult do you think it will be for you to avoid drinking alcohol
        for the next week?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Extremely easy", "Extremely hard"]}
        sliderType={Slider.SliderType.horizontal}
        extraRadio={["I don't know"]}
      />
    )
  },
  {
    id: "LQ_DIS_6",
    mandatory: true,
    plainTitle: "Expected difficulty for avoding drugs",
    title: (
      <span>
        How difficult do you think it will be for you to avoid taking drugs,
        smoking, or partying for the next week?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Extremely easy", "Extremely hard"]}
        sliderType={Slider.SliderType.horizontal}
        extraRadio={["I don't know"]}
      />
    )
  },
  {
    id: "LQ_DIS_7",
    mandatory: true,
    plainTitle: "Expected difficulty for 24h awake",
    title: (
      <span>
        How difficult do you think it will be for you to stay awake for 24 hours
        after having only slept 4 hours?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Extremely easy", "Extremely hard"]}
        sliderType={Slider.SliderType.horizontal}
        extraRadio={["I don't know"]}
      />
    )
  },
  {
    id: "LQ_DIS_8",
    mandatory: true,
    plainTitle: "Expected difficulty for sleeping with EEG",
    title: (
      <span>
        How much do you think sleeping in the lab with an EEG cap will affect
        your sleep quality?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Not at all", "Won't be able to sleep"]}
        sliderType={Slider.SliderType.horizontal}
        extraRadio={["I don't know"]}
      />
    )
  },
  {
    id: "LQ_DIS_9.1",
    mandatory: false,
    plainTitle: "Normal thought patterns",
    title: (
      <span>
        When you don’t have anything to do, what do you most often think about?
      </span>
    ),
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "Daydream" },
          { text: "Worry" },
          { text: "Plan" },
          { text: "Jumbled thoughts, uncontrolled stream of consciousness" },
          { text: "Blank mind" },
          { text: "Meditate" },
          { text: "Focus on something external" },
          { text: "Focus on something internal" },
          { text: "Remember/reminisce" },
          { text: "I don't know" },
          { text: "Other", extraInput: "string" }
        ]}
      />
    )
  },
  {
    id: "LQ_DIS_9.2",
    mandatory: false,
    plainTitle: "Enjoy your own thoughts?",
    title: (
      <span>How much do you like spending time with your own thoughts?</span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["I hate it", "Neutral", "I love it"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_DIS_10.1",
    mandatory: false,
    plainTitle: "Daydreaming frequency",
    title: <span>How often do you daydream?</span>,
    explanation:
      "Daydreaming refers to any time you spend imagining a scenario that isn't real and (most likely) never will be.",
    component: v => (
      <Slider.Question
        onValid={v}
        labels={[
          "Never",
          "Sometimes",
          "Often",
          "At least once per day",
          "Any time I can"
        ]}
        sliderType={Slider.SliderType.horizontal}
        extraRadio={["I can't daydream"]}
      />
    )
  },
  {
    id: "LQ_DIS_11.2",
    mandatory: false,
    plainTitle: "Visual imagination vividness",
    title: <span>Can you easily picture things in your mind?</span>,
    explanation:
      "For example: imagine a rainbow elephant covered in scales, wearing pyjamas.",
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["I can't at all", "I can practically see it now"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_DIS_11.3",
    mandatory: false,
    plainTitle: "Motor imagination vividness",
    title: (
      <span>
        Can you easily imagine performing complicated movements, even ones you
        can’t actually do?
      </span>
    ),
    explanation: "For example: gymnastic backflips, or martial arts.",
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["I can't at all", "I can practically do it now"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  }
];

export default questions;
