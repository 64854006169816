import React from "react";
import { QuestionType, Question } from "ewoq/containers/Interrogator";
import * as RadioTable from "ewoq/prototypes/RadioTable";
import * as Radio from "ewoq/prototypes/Radio";
import * as YesNoQ from "ewoq/prototypes/YesNo";
import * as Input from "ewoq/prototypes/TypeInput";
import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";
import * as Slider from "ewoq/prototypes/Slider";
import * as Range from "ewoq/prototypes/Ranges";

const questions: QuestionType[] = [
  {
    id: "LQ_HEA_1.1",
    mandatory: true,
    plainTitle: "Gender",
    title: <span>What is your gender? </span>,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: "Male" },
          { text: "Female" },
          { text: "", extraInput: "string" }
        ]}
      />
    )
  }, // the ID is from the original ethics
  {
    id: "LQ_HEA_1.2",
    mandatory: false,
    plainTitle: "Biological sex?",
    title: <span>Does this match your biological sex?</span>,
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "LQ_HEA_2",
    mandatory: true,
    plainTitle: "Glasses?",
    title: <span>To see better, do you wear:</span>,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: "Glasses" },
          { text: "Contact lenses" },
          { text: "Either glasses or contacts" },
          { text: "Nothing, I have perfect vision" },
          { text: "Nothing, but I struggle to see" }
        ]}
      />
    )
  },

  {
    id: "LQ_HEA_3.1",
    mandatory: true,
    plainTitle: "Contraception?",
    title: <span>Do you take hormonal contraception?</span>,
    shouldShow: (a: Question[]) => {
      const idxGender = a.findIndex(a => a.id === "LQ_HEA_1.1");

      const idxSex = a.findIndex(a => a.id === "LQ_HEA_1.2");
      return (
        a[idxGender].data !== undefined &&
        a[idxGender].data["answer"] > 0 &&
        (a[idxSex].data === undefined ||
          a[idxSex].data["answer"] === YesNoQ.AnswerType.yes)
      ); // asks if person is biologically female whether they take contraception
    },
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "LQ_HEA_3.2",
    mandatory: true,
    plainTitle: "Last period",
    title: <span>When was your last period?</span>,
    explanation:
      "Indicate how many days it has been since your period started.",
    shouldShow: (a: Question[]) => {
      const idxGender = a.findIndex(a => a.id === "LQ_HEA_1.1");

      const idxSex = a.findIndex(a => a.id === "LQ_HEA_1.2");
      return (
        a[idxGender].data !== undefined &&
        a[idxGender].data["answer"] > 0 &&
        (a[idxSex].data === undefined ||
          a[idxSex].data["answer"] === YesNoQ.AnswerType.yes)
      ); // asks if person is biologically female whether they take contraception
    },
    component: v => (
      <Input.Question onValid={v} inputType="string" placeholder="days ago" />
    )
  },
  {
    id: "LQ_HEA_3.3",
    mandatory: true,
    plainTitle: "Regular cycle?",
    title: <span>Is your cycle regular?</span>,
    shouldShow: (a: Question[]) => {
      const idxGender = a.findIndex(a => a.id === "LQ_HEA_1.1");
      const idxSex = a.findIndex(a => a.id === "LQ_HEA_1.2");
      const idxContracept = a.findIndex(a => a.id === "LQ_HEA_3.1");
      return (
        a[idxGender].data !== undefined &&
        a[idxGender].data["answer"] > 0 &&
        (a[idxSex].data === undefined ||
          a[idxSex].data["answer"] === YesNoQ.AnswerType.yes) &&
        (a[idxContracept].data === undefined ||
          a[idxContracept].data["answer"] === YesNoQ.AnswerType.no)
      ); // asks if person is biologically female whether they take contraception
    },
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Predictable, like clockwork", "Completely unpredictable"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_HEA_3.4",
    mandatory: false,
    plainTitle: "Mood swings?",
    title: (
      <span>
        Do you suffer from large mood swings due to your hormonal cycle?
      </span>
    ),
    shouldShow: (a: Question[]) => {
      const idxGender = a.findIndex(a => a.id === "LQ_HEA_1.1");

      const idxSex = a.findIndex(a => a.id === "LQ_HEA_1.2");
      return (
        a[idxGender].data !== undefined &&
        a[idxGender].data["answer"] > 0 &&
        (a[idxSex].data === undefined ||
          a[idxSex].data["answer"] === YesNoQ.AnswerType.yes)
      ); // asks if person is biologically female whether they take contraception
    },
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Not at all", "Somewhat / sometimes", "A lot"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_HEA_3.5",
    mandatory: false,
    plainTitle: "Period pains?",
    title: <span>Is your period painful?</span>,
    shouldShow: (a: Question[]) => {
      const idxGender = a.findIndex(a => a.id === "LQ_HEA_1.1");

      const idxSex = a.findIndex(a => a.id === "LQ_HEA_1.2");
      return (
        a[idxGender].data !== undefined &&
        a[idxGender].data["answer"] > 0 &&
        (a[idxSex].data === undefined ||
          a[idxSex].data["answer"] === YesNoQ.AnswerType.yes)
      ); // asks if person is biologically female whether they take contraception
    },
    component: v => (
      <Slider.Question
        onValid={v}
        labels={[
          "No pain",
          "Mild discomfort",
          "Painful",
          "Extremely painful",
          "Worst possible pain"
        ]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_HEA_3.6",
    mandatory: true,
    plainTitle: "Period and sleep quality",
    title: <span>Does your period affect your sleep quality?</span>,
    shouldShow: (a: Question[]) => {
      const idxGender = a.findIndex(a => a.id === "LQ_HEA_1.1");

      const idxSex = a.findIndex(a => a.id === "LQ_HEA_1.2");
      return (
        a[idxGender].data !== undefined &&
        a[idxGender].data["answer"] > 0 &&
        (a[idxSex].data === undefined ||
          a[idxSex].data["answer"] === YesNoQ.AnswerType.yes)
      ); // asks if person is biologically female whether they take contraception
    },
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Not at all", "Somewhat", "A lot"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_HEA_4.1",
    mandatory: false,
    plainTitle: "Drug use?",
    title: <span>Do you take recreational drugs?</span>,
    explanation: (
      <span>
        These questions are optional because they are sensitive, but it can
        matter quite a lot for data quality, and your answers remain as
        confidential as ever.
      </span>
    ),
    component: v => (
      <RadioTable.Question
        onValid={v}
        subQuestions={[
          "Marijuana, hashish, weed",
          "MDMA (ecstasy, E)",
          "LSD",
          "Mescaline, psilocybin (magic mushrooms)",
          " Amphetamines (speed)",
          "Ketamine",
          "GHB",
          "Meth",
          "Heroine, fentanyl, opiates (painkillers)",
          "Steroids",
          "Cocaine",
          "Other"
        ]}
        options={[
          "Never/only tried once",
          "<1 per year",
          "<1 per month",
          "<1 per week",
          "Often"
        ]}
      />
    )
  },
  {
    id: "LQ_HEA_4.2",
    mandatory: false,
    plainTitle: "Drug use in past month?",
    title: (
      <span>Have you taken any of the following drugs in the past month?</span>
    ),
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "Marijuana, hashish, weed" },
          { text: "MDMA (ecstasy, E)" },
          { text: "LSD" },
          { text: "Mescaline, psilocybin (magic mushrooms)" },
          { text: " Amphetamines (speed)" },
          { text: "Ketamine" },
          { text: "GHB" },
          { text: "Meth" },
          { text: "Heroine, fentanyl, opiates (painkillers)" },
          { text: "Steroids" },
          { text: "Cocaine" },
          { text: "Other" }
        ]}
        allAbove={true}
      />
    )
  },
  {
    id: "LQ_HEA_4.3",
    mandatory: false,
    plainTitle: "Marijuana extras?",
    title: (
      <span>
        Do you ever consume marijuana products such as “edibles”, drinks, oils,
        CBD?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Never", "Yearly", "Monthly", "Weekly", "Daily"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_HEA_4.4",
    mandatory: true,
    plainTitle: "Bad drug experiences?",
    title: (
      <span>
        Have you recently (past 6 months) had a bad experience with any drug?
      </span>
    ),
    component: v => (
      <Radio.Question
        onValid={v}
        options={[{ text: "Yes" }, { text: "No" }, { text: "Maybe" }]}
      />
    )
  },
  {
    id: "LQ_HEA_5.1",
    mandatory: false,
    plainTitle: "Regular perscription medication?",
    title: (
      <span>
        Have you ever had to take regular prescription medication for more than
        3 months?
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "LQ_HEA_5.2",
    mandatory: false,
    plainTitle: "What medication did you take?",
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_HEA_5.1");
      return (
        a[idx].data !== undefined &&
        a[idx].data["answer"] === YesNoQ.AnswerType.yes
      );
    },
    title: <span>What medication did you take?</span>,
    component: v => <Input.Question onValid={v} inputType="string" />
  },
  {
    id: "LQ_HEA_5.3",
    mandatory: false,
    plainTitle: "At what age?",
    title: <span>In which age range did you take it?</span>,
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_HEA_5.1");
      return (
        a[idx].data !== undefined &&
        a[idx].data["answer"] === YesNoQ.AnswerType.yes
      );
    },
    component: v => (
      <Range.Question
        onValid={v}
        labels={["0-5", "6-10", "11-15", "15-20", "20-25"]}
      />
    )
  },
  {
    id: "LQ_HEA_6.1",
    mandatory: true,
    plainTitle: "Ever had major surgery?",
    title: (
      <span>
        Have you ever had major surgery (requiring full body anesthesia)?
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "LQ_HEA_6.2",
    mandatory: false,
    plainTitle: "At what age?",
    title: <span>In which age range did you have the surgery/surgeries?</span>,
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_HEA_6.1");
      return (
        a[idx].data !== undefined &&
        a[idx].data["answer"] === YesNoQ.AnswerType.yes
      );
    },
    component: v => (
      <Range.Question
        onValid={v}
        labels={["0-5", "6-10", "11-15", "15-20", "20-25"]}
      />
    )
  },
  {
    id: "LQ_HEA_7.1",
    mandatory: true,
    plainTitle: "Ever smoked?",
    title: <span>Have you ever smoked cigarettes?</span>,
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "LQ_HEA_7.2",
    mandatory: false,
    plainTitle: "When smoked?",
    title: <span>In which age range did you smoke?</span>,
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_HEA_7.1");
      return (
        a[idx].data !== undefined &&
        a[idx].data["answer"] === YesNoQ.AnswerType.yes
      );
    },
    component: v => (
      <Range.Question
        onValid={v}
        labels={["0-5", "6-10", "11-15", "15-20", "20-25"]}
      />
    )
  },
  {
    id: "LQ_HEA_7.3",
    mandatory: true,
    plainTitle: "Smoked this week?",
    title: <span>Have you smoked in the past week?</span>,
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_HEA_7.1");
      return (
        a[idx].data !== undefined &&
        a[idx].data["answer"] === YesNoQ.AnswerType.yes
      );
    },
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          {
            text: "Yes",
            extraInput: "number",
            placeHolder: "number of cigarettes"
          },
          { text: "No" }
        ]}
      />
    )
  },
  {
    id: "LQ_HEA_7.4",
    mandatory: true,
    plainTitle: "Smoked this month?",
    title: <span>Have you smoked in the past month?</span>,
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "LQ_HEA_7.1");
      return (
        a[idx].data !== undefined &&
        a[idx].data["answer"] === YesNoQ.AnswerType.yes
      );
    },
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          {
            text: "Yes",
            extraInput: "number",
            placeHolder: "number of cigarettes"
          },
          { text: "No" }
        ]}
      />
    )
  },
  {
    id: "LQ_HEA_8",
    mandatory: true,
    plainTitle: "Allergies?",
    title: <span>Do you have allergies:</span>,
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "None" },
          { text: "Pollen (seasonal)" },
          { text: "Nuts" },
          { text: "Some medicines (e.g. penicillin)" },
          { text: "Some skin products/chemicals" },
          { text: "Other", extraInput: "string" }
        ]}
      />
    )
  } //TODO if ever I have time, fix the interrogator so that it doesn't freeze if the last answers are hidden, then move this question back up
];

export default questions;
