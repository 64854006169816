import React from "react";
import { Interrogator, QuestionsObj } from "ewoq/containers/Interrogator";
import questions from "./Questions/SleepQuestions";
import { answerQuestionnaire } from "ewoq/api";

interface Props {
  onDone: (s: boolean) => void;
  viewAll?: boolean;
}

class SleepQ extends React.Component<Props> {
  onSubmit = (answers: QuestionsObj) => {
    let isEligible = Object.values(answers).every(a => a.isOk);
    this.props.onDone(isEligible);

    answerQuestionnaire("LSM", "Screening-Sleep", {
      answers: answers,
      isEligible: isEligible
    });
  };

  render() {
    return (
      <div>
        <Interrogator
          questions={questions}
          onDone={this.onSubmit}
          viewAll={this.props.viewAll}
        />
      </div>
    );
  }
}

export default SleepQ;
