import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";
import { getUserId, setUserId } from "ewoq/utils";
import { obtainUserId, listQueue, ApiRequest } from "ewoq/api";

import {
  SectionBody,
  HeaderContainer,
  ParticipantID,
  Squeeze,
  LinkQ
} from "ewoq/components/miscStyles";

interface Props {}

interface State {
  cookie: string;
  words: string[];
}

const Center = styled.p`
  text-align: center;
`;

class Questionnaires extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const cookie = getUserId() || ""; //I shouldn't have to do this
    this.state = {
      cookie,
      words: Array(3).fill(undefined)
    };
  }

  updateCookie = () => {
    const unsaved = this.checkUnsaved();
    if (unsaved) {
      this.forceUpdate();
      return;
    } else {
      const words = this.state.words;
      if (words.every(w => w.length > 0)) {
        const cookie = words[0] + "-" + words[1] + "-" + words[2];
        setUserId(cookie);
        this.forceUpdate();
      }
    }
  };

  onChange = (idx: number) => (a: any, b: any) => {
    let words = this.state.words.slice();
    words[idx] = b.value.trim();
    this.setState({ words });
  };

  newCookie = () => {
    const unsaved = this.checkUnsaved();
    if (unsaved) {
      this.forceUpdate();
      return;
    } else {
      setUserId("");
      obtainUserId().then(() => this.forceUpdate());
    }
  };

  checkUnsaved = () => {
    const unsaved = !listQueue().every(k => {
      const data = JSON.parse(localStorage[k]) as ApiRequest;
      return data.isOnline;
    });
    return unsaved;
  };

  render() {
    const unsaved = this.checkUnsaved();
    return (
      <div>
        <ParticipantID>{getUserId()}</ParticipantID>
        <HeaderContainer>All Questionnaires</HeaderContainer>
        <Ui.Container>
          <Ui.Segment>
            {unsaved ? (
              <Squeeze>
                <Ui.Message warning>
                  <Ui.Message.Header>
                    You have unsaved questionnaires
                  </Ui.Message.Header>
                  <Ui.Message.Content>
                    Find some wifi and refresh this page.
                  </Ui.Message.Content>
                </Ui.Message>
              </Squeeze>
            ) : (
              <div>
                <SectionBody>
                  <Ui.Form>
                    <Ui.Header textAlign="center">Choose participant</Ui.Header>
                    <Ui.Form.Group widths="equal">
                      <Ui.Form.Input
                        onChange={this.onChange(0)}
                        value={this.state.words[0]}
                      />
                      <Ui.Form.Input
                        onChange={this.onChange(1)}
                        value={this.state.words[1]}
                      />
                      <Ui.Form.Input
                        onChange={this.onChange(2)}
                        value={this.state.words[2]}
                      />
                    </Ui.Form.Group>
                    <Ui.Form.Field>
                      <Ui.Button
                        onClick={this.updateCookie}
                        disabled={
                          !this.state.words.every(w => w && w.length > 0)
                        }
                      >
                        Change
                      </Ui.Button>
                    </Ui.Form.Field>
                  </Ui.Form>
                </SectionBody>
              </div>
            )}
          </Ui.Segment>

          <Ui.Segment>
            <Ui.Header textAlign="center">New Questionnaire</Ui.Header>
            <Squeeze>
              <LinkQ
                href="https://lsm.schlaflab.com/pilotscreening"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Ui.Button color="grey" fluid>
                  Screening Questionnaire
                </Ui.Button>
              </LinkQ>

              <LinkQ
                href="https://lsm.schlaflab.com/lifestyle"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Ui.Button color="teal" fluid>
                  Lifestyle Questionnaire
                </Ui.Button>
              </LinkQ>

              <LinkQ
                href="https://lsm.schlaflab.com/sleepreport"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Ui.Button color="teal" fluid>
                  Sleep Report
                </Ui.Button>
              </LinkQ>
              <LinkQ
                href="https://lsm.schlaflab.com/battery"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Ui.Button color="teal" fluid>
                  Test Battery Mini Questionnaires
                </Ui.Button>
              </LinkQ>
              <LinkQ
                href="https://lsm.schlaflab.com/resttest"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Ui.Button color="teal" fluid>
                  Regular Rest Test Questionnaire
                </Ui.Button>
              </LinkQ>
              <LinkQ
                href="https://lsm.schlaflab.com/debriefing"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Ui.Button color="teal" fluid>
                  Debriefing Questionnaire
                </Ui.Button>
              </LinkQ>
            </Squeeze>
          </Ui.Segment>
          <Ui.Segment>
            <Ui.Header textAlign="center">Questionnaires Submitted</Ui.Header>
            <Squeeze>
              {listQueue()
                .sort()
                .map((k, idx) => {
                  const data = JSON.parse(localStorage[k]) as ApiRequest;
                  return (
                    <Center key={idx}>
                      <Ui.Icon
                        color={data.isOnline ? "teal" : "grey"}
                        name="check"
                      />
                      {k}
                    </Center>
                  );
                })}
            </Squeeze>
            <p>
              If you don't see the latest questionniare, try refreshing the
              page.
            </p>
          </Ui.Segment>
        </Ui.Container>
      </div>
    );
  }
}

export default Questionnaires;
