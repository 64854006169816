import React from "react";
import * as Ui from "semantic-ui-react";

import { HeaderContainer, ParticipantID } from "ewoq/components/miscStyles";
import ChooseSession from "ewoq/containers/DayPicker";
import { getUserId } from "ewoq/utils";
import { QuestionsObj, Interrogator } from "ewoq/containers/Interrogator";
import { answerQuestionnaire } from "ewoq/api";

import questions from "./BatteryQuestions";

interface Props {
  viewAll?: boolean;
}

interface State {
  session: string;
  task: string;
  device: string;
}

class Battery extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      session: "",
      task: "",
      device: "",
    };
  }

  onSubmit = (answers: QuestionsObj) => {
    answerQuestionnaire(
      "LSM",
      this.state.session + "-" + this.state.task + "-" + this.state.device,
      {
        answers: answers,
      }
    );
    this.setState({ task: "" });
  };

  chooseTask = (selection: string) => {
    this.setState({ task: selection });
  };

  chooseDevice = (selection: string) => {
    this.setState({ device: selection });
  };

  chooseSession = (selection: string) => {
    this.setState({ session: selection });
  };

  render() {
    return (
      <div>
        <ParticipantID>{getUserId()}</ParticipantID>
        <HeaderContainer>Test Battery</HeaderContainer>
        <Ui.Container>
          <ChooseSession
            sessions={[["Adaptation"], ["Pre", "Session1", "Session2", "Post"]]}
            selection={this.state.session}
            onClick={this.chooseSession}
            title="Session"
          />
          <ChooseSession
            sessions={[
              ["match2sample", "SpFT", "Game"],
              ["LAT", "PVT", "Music"],
              ["LAT1", "LAT2", "LAT3"],
            ]}
            selection={this.state.task}
            onClick={this.chooseTask}
            title="Task"
          />
          <ChooseSession
            sessions={[["laptop", "projector"]]}
            selection={this.state.device}
            onClick={this.chooseDevice}
            title="Device"
          />
        </Ui.Container>
        {this.state.task && this.state.session && this.state.device ? (
          <Interrogator
            questions={questions}
            onDone={this.onSubmit}
            viewAll={this.props.viewAll}
          />
        ) : null}
      </div>
    );
  }
}

export default Battery;
