import React from "react";

import { QuestionType, Question } from "ewoq/containers/Interrogator";
import * as Input from "ewoq/prototypes/TypeInput";
import * as Slider from "ewoq/prototypes/Slider";
import * as SliderGroup from "ewoq/prototypes/SliderGroup";
import * as Radio from "ewoq/prototypes/Radio";
import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";
import * as GroupQuestion from "ewoq/prototypes/GroupQuestions";
import { SubQuestions } from "ewoq/prototypes/GroupQuestions";
import {
  feelings,
  extraFeelings
} from "Questionnaires/StandardQuestionComponents/Feelings";
import { kss } from "Questionnaires/StandardQuestionComponents/KSS";
import { fourEnergiesPresent } from "Questionnaires/StandardQuestionComponents/FourEnergies";

interface subQuestionsObjType {
  [s: string]: SubQuestions;
}

const SubQs: subQuestionsObjType = {
  b: {
    id: "b",
    title: "What about?",
    explanation: "in just 1 sentence.",
    component: v => <Input.Question onValid={v} inputType="string" />
  },
  c: {
    id: "c",
    title: "How coherent were your thoughts?",
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Incoherent, fragmented, confused", "clear"]}
      />
    )
  },
  e: {
    id: "e",
    title: "Characterize the content:",
    component: v => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          { labels: ["Vivid", "Vague"] },
          { labels: ["Social", "Non-social"] },
          { labels: ["Realistic", "Fantasy"] },
          { labels: ["Complicated", "Simple"] },
          { labels: ["Lots of movement", "Stationary"] },
          { labels: ["Egocentric", "Completely external"] }
        ]}
      />
    )
  },
  f: {
    id: "f",
    title: "Senses involved:",
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "Visual" },
          { text: "Auditory" },
          { text: "Olfactory" },
          { text: "Taste" },
          { text: "Other" }
        ]}
        includeDone={false}
      />
    )
  },
  g: {
    id: "g",
    title: "Speed of your thoughts:",
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Slow", "Fast"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  }
};

const questions: QuestionType[] = [
  {
    id: "RT_OVR_1",
    plainTitle: "rest test experience",
    title: <span>How did you experience the past half hour?</span>,
    component: v => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          { labels: ["Miserable", "Neutral", "Enjoyable"] },
          { labels: ["Frustrating", "Neutral", "Relaxing"] }
        ]}
      />
    )
  },
  {
    id: "RT_OVR_2",
    plainTitle: "time passed",
    title: (
      <span>
        How much time do you feel passed, in minutes from when the recording
        started?
      </span>
    ),
    component: v => (
      <Input.Question onValid={v} inputType="number" placeholder="minutes" />
    )
  },
  {
    id: "RT_OVR_3.1",
    plainTitle: "difficulty fixating",
    title: <span>How hard was it to maintain fixation?</span>,
    mandatory: false,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Extremely easy", "Extremely hard"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "RT_OVR_3.2",
    plainTitle: "difficulty staying awake",
    title: <span>How hard was it to stay awake/keep your eyes open?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Extremely easy", "Extremely hard"]}
        sliderType={Slider.SliderType.horizontal}
        extraRadio={["I fell asleep"]}
      />
    )
  },
  {
    id: "RRT_OVT_4",
    plainTitle: "Motivation for the task",
    mandatory: false,
    title: <span>How motivated were you during the task?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          "I really did not want to do it",
          "I didn't mind doing it",
          "I was really motivated to do it"
        ]}
      />
    )
  },
  {
    id: "RT_THO_1",
    plainTitle: "Thought of what?",
    title: <span>Please indicate which of the following you engaged in:</span>,
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "Daydreaming" },
          { text: "Worrying" },
          { text: "Planning" },
          { text: "Jumbled thoughts/uncontrolled stream of consciousness" },
          { text: "Blank mind" },
          { text: "Focused on something" },
          { text: "Remembering" },
          { text: "I don't remember" },
          { text: "Other", extraInput: "string" }
        ]}
      />
    )
  },
  {
    id: "RT_THO_Daydreaming",
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "RT_THO_1");
      return a[idx].data !== undefined && a[idx].data["answers"][0];
    },
    plainTitle: "Daydereaming content",
    title: "Regarding the time you spent daydreaming:",
    component: v => (
      <GroupQuestion.Questions
        onValid={v}
        subQuestions={[SubQs.b, SubQs.c, SubQs.e, SubQs.f, SubQs.g]}
      />
    )
  },

  {
    id: "RT_THO_Worrying",
    title: "Regarding the time you spent worrying:",
    plainTitle: "worrying content",
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "RT_THO_1");
      return a[idx].data !== undefined && a[idx].data["answers"][1];
    },
    component: v => (
      <GroupQuestion.Questions
        onValid={v}
        subQuestions={[SubQs.b, SubQs.c, SubQs.g]}
      />
    )
  },
  {
    id: "RT_THO_Planning",
    title: "Regarding the time you spent planning:",
    plainTitle: "Planning content",
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "RT_THO_1");
      return a[idx].data !== undefined && a[idx].data["answers"][2];
    },
    component: v => (
      <GroupQuestion.Questions
        onValid={v}
        subQuestions={[SubQs.b, SubQs.c, SubQs.g]}
      />
    )
  },
  {
    id: "RT_THO_Jumbled",
    title:
      "Regarding the time you spent in an uncontrolled stream of consciousness:",
    plainTitle: "uncontrolled thoughts content",
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "RT_THO_1");
      return a[idx].data !== undefined && a[idx].data["answers"][3];
    },
    component: v => (
      <GroupQuestion.Questions onValid={v} subQuestions={[SubQs.g]} />
    )
  },
  {
    id: "RT_THO_Focus",
    title: "Regarding the time you spent focused:",
    plainTitle: "Focused content",
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "RT_THO_1");
      return a[idx].data !== undefined && a[idx].data["answers"][5];
    },
    component: v => (
      <GroupQuestion.Questions
        onValid={v}
        subQuestions={[
          SubQs.b,
          SubQs.c,
          SubQs.f,
          {
            id: "location",
            title: "Where was the focus of your attention?",
            component: v => (
              <MultipleChoice.Question
                onValid={v}
                options={[{ text: "External" }, { text: "Internal" }]}
                includeDone={false}
              />
            )
          }
        ]}
      />
    )
  },
  {
    id: "RT_THO_Remembering",
    title: "Regarding the time you spent remembering:",
    plainTitle: "Remembering content",
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "RT_THO_1");
      return a[idx].data !== undefined && a[idx].data["answers"][6];
    },
    component: v => (
      <GroupQuestion.Questions
        onValid={v}
        subQuestions={[SubQs.b, SubQs.c, SubQs.e, SubQs.f, SubQs.g]}
      />
    )
  },
  {
    id: "RT_THO_Other",
    title: "Regarding the time you spent doing something else:",
    plainTitle: "Other content",
    shouldShow: (a: Question[]) => {
      const idx = a.findIndex(a => a.id === "RT_THO_1");
      return a[idx].data !== undefined && a[idx].data["answers"][8];
    },
    component: v => (
      <GroupQuestion.Questions
        onValid={v}
        subQuestions={[SubQs.b, SubQs.c, SubQs.g]}
      />
    )
  },
  {
    id: "RT_TIR_1",
    mandatory: false,
    plainTitle: "RRt KSS",
    title: <span>Please indicate your sleepiness during the test: </span>,
    component: v => kss(v)
  },
  {
    id: "RT_TIR_2",
    mandatory: false,
    plainTitle: "RRT four energies",
    title: <span>How do you feel: </span>,
    component: v => fourEnergiesPresent(v)
  },
  {
    id: "RT_TIR_3.1",
    mandatory: false,
    plainTitle: "RRT did you sleep",
    title: <span>Have you fallen asleep while: </span>,
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "Watching TV" },
          { text: "Doing the rest task" },
          { text: "During the break" },
          { text: "Not at all" }
        ]}
      />
    )
  },
  {
    id: "RT_TIR_3.2",
    mandatory: false,
    plainTitle: "Sleep confidence ",
    title: <span>How sure are you of your answer: </span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["0%", "100% sure"]}
      />
    )
  },
  {
    id: "RT_TIR_4",
    mandatory: false,
    plainTitle: "Alertness",
    title: (
      <span>
        How alert are you? How quickly do you feel you would respond to
        something (e.g. a ball thrown at your head)?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Slow, sluggish", "Normal", "Hyperalert"]}
      />
    )
  },
  {
    id: "RT_TIR_5",
    mandatory: false,
    title: <span>How much do you want to go to sleep right now?</span>,
    plainTitle: "sleep pressure",
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: "I can't sleep right now" },
          { text: "I would have a hard time falling asleep" },
          { text: "I might sleep" },
          { text: "I would like to sleep" },
          { text: "I really want to sleep" },
          { text: "This is the most I've ever wanted to sleep" },
          { text: "I feel tired, but I don't think I could sleep" }
        ]}
      />
    )
  },
  {
    id: "RT_TIR_6",
    plainTitle: "Focus:",
    title: <span>How focused / concentrated are you at the moment?</span>,
    explanation:
      "Imagine you are in a lecture, how capable would you be of paying attention?",
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Very unfocused", "Very concentrated"]}
      />
    )
  },
  {
    id: "RT_FEE_1.1",
    mandatory: true,
    plainTitle: "Feelings:",
    title: "Indicate on the following scales how you feel right now:",
    component: v => feelings(v)
  },
  {
    id: "RT_FEE_1.2",
    mandatory: true,
    plainTitle: "extra feelings",
    title: "Check all that apply:",
    component: v => extraFeelings(v)
  },
  {
    id: "RT_FEE_2",
    mandatory: true,
    title: "How tolerant do you feel towards the experiment right now?",
    plainTitle: "Experiment tolerance:",
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.vertical}
        labels={[
          "Completely tolerant",
          "Mostly tolerant",
          "Starting to lose patience",
          "Somewhat intolerant",
          "At the end of my rope"
        ]}
      />
    )
  },
  {
    id: "RT_FEE_3",
    mandatory: true,
    title: "Do you feel...",
    plainTitle: "Stress:",
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Relaxed", "Tense", "Stressed"]}
      />
    )
  },
  {
    id: "RT_FEE_4",
    mandatory: true,
    plainTitle: "current state",
    title:
      "Please answer these questions taking into consideration both your current feelings, and what you felt over the past half hour.",

    component: v => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          {
            question: "How hungry are you?",
            labels: ["Not at all", "Extremely"]
          },
          {
            question: "How thirsty are you?"
          },
          {
            question: "Do you have a headache?"
          },
          {
            question: "Do you feel any other pain or discomfort?"
          },
          {
            question: "How motivated do you feel?"
          }
        ]}
      />
    )
  },
  {
    id: "RT_FEE_5",
    mandatory: false,
    plainTitle: "General feelings:",
    title: <span>What is your mood right now?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Very bad", "Very good"]}
      />
    )
  },
  {
    id: "RT_oddball",
    mandatory: false,
    plainTitle: "task difficulty:",
    title: <span>How difficult was the task?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Extremely easy", "Extremely hard"]}
      />
    )
  }
];

export default questions;
