import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Input from "ewoq/prototypes/TypeInput";
import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";
import * as RepeatGroupQuestions from "ewoq/prototypes/RepeatGroupQuestions";

import { threeHoursDay } from "Questionnaires/StandardQuestionComponents/TimeofDay";

const foodList = [
  { text: "Carbohydrates (rice, pasta, bread, cereal, etc)" },
  { text: "Fruits" },
  { text: "Vegetables" },
  { text: "Legumes (beans, soy, peas, lentils)" },
  { text: "Dairy (yoghurt, cheese)" },
  { text: "Meat" },
  { text: "Fish, seafood" },
  { text: "Eggs" },
  { text: "Sweets" },
  { text: "Other: ", extraInput: "string" },
  { text: "Nothing, I skipped the meal" }
];

const questions: QuestionType[] = [
  {
    id: "SR_FOO_1",
    mandatory: false,
    plainTitle: "Caffeine drinks yesterday",
    title: (
      <span>
        Please list how many <b>caffeinated drinks</b> you had:
      </span>
    ),
    explanation: "Including tea, cola, energy drinks, etc.",
    component: v => (
      <RepeatGroupQuestions.Questions
        onValid={v}
        subQuestions={[
          {
            id: "type",
            title: "Type:",
            component: v => (
              <Input.Question
                onValid={v}
                inputType="string"
                placeholder="e.g. cappuccino"
              />
            )
          },
          {
            id: "number",
            title: "How many?",
            component: v => (
              <Input.Question onValid={v} inputType="number" placeholder="1" />
            )
          },
          {
            id: "when",
            title: "When:",
            component: v => threeHoursDay(v, false)
          }
        ]}
      />
    )
  },
  {
    id: "SR_FOO_2.1",
    mandatory: false,
    plainTitle: "breakfast foods",
    title: "What did you eat for breakfast? Tick all that apply:",
    component: v => <MultipleChoice.Question onValid={v} options={foodList} />
  },
  {
    id: "SR_FOO_2.2",
    mandatory: false,
    plainTitle: "lunch foods",
    title: "What did you eat for lunch? Tick all that apply:",
    component: v => <MultipleChoice.Question onValid={v} options={foodList} />
  },
  {
    id: "SR_FOO_2.3",
    mandatory: false,
    plainTitle: "dinner foods",
    title: "What did you eat for dinner? Tick all that apply:",
    component: v => <MultipleChoice.Question onValid={v} options={foodList} />
  }
];
export default questions;

//TODO: add plain titles and change numbering, and update questions
